$(window).load(function () {
    if ($(".zopim").length && typeof $zopim !== 'undefined') {
        $zopim(function () {
            $zopim.livechat.setOnStatus(bubble);

            function bubble(status) {
                if (status == 'offline') {
                    $("#contactbar .zopim-check.office-open").addClass("hidden");
                    $("#contactbar .zopim-check.office-closed").removeClass("hidden");
                }
            }
        });
    }
});

$(function () {
	svg4everybody(); // run "SVG for Everybody", which adds SVG External Content support to all browsers.

	$("#travel-list").on("click", ".inquire", function (e) {
		var currentUrl = window.location.href;

		var hotelElement = $(e.target).parents(".hotel");

		var hotelCode = hotelElement.data("hotelcode");
		var hotelName = hotelElement.find("h3 a").attr("title");

		if (currentUrl.indexOf("?") < 0) {
			var season = $("#season").val();
			var transport = $("#transport").val();
			var days = $("#dage").val();
			var date = $("#dato").val();
			var adults = $("#voksne").val();
			var children = $("#born").val();

			var agesFields = $("input[name=alder]");
			var ages = "";
			agesFields.each(function (index, element) {
				var is_last_item = (index == (agesFields.length - 1));
				ages += $(this).val();
				if (!is_last_item) {
					ages += ",";
				}
			});

			currentUrl += "?saeson=" + season + "&transport=" + transport + "&dage=" + days + "&dato=" + date + "&voksne=" + adults + "&born=" + children + "&alder=" + ages;
		}

		$('#inquire-form-modal h2').text(hotelName);

		$('#inquire-form-modal .hidden.search').val(currentUrl);
		$('#inquire-form-modal .hidden.hotel').val(hotelCode);

		$('#inquire-form-modal').modal();

	});

    if ($(".viewer-image").length) {
        $('.viewer-image').each(function () {
            var viewer = new Viewer(this,
            {
                url: 'data-original',
                navbar: 0,
                toolbar: 2,
                rotatable: 0,
                scalable: 0,
                movable: 1,
                title: 0
            });
            $(document).on("click", ".viewer-canvas", function (e) {
                if ($(e.target).is('img')) {
                    e.preventDefault();
                    return;
                }
                viewer.hide();
            });
        });
    }

    if ($(".viewer-gallery").length) {
        $('.viewer-gallery').each(function () {
            var viewer = new Viewer(this,
            {
                url: 'data-original',
                navbar: 0,
                toolbar: 2,
                rotatable: 0,
                scalable: 0,
                movable: 0,
                zoomable: 0,
                title: 0
            });

            $(document).on("click", ".viewer-canvas", function (e) {
                if ($(e.target).is('img')) {
                    e.preventDefault();
                    return;
                }
                viewer.hide();
            });

            $('body').on("swiperight", '.viewer-canvas', function () {
                viewer.prev();
            });
            $('body').on("swipeleft", '.viewer-canvas', function () {
                viewer.next();
            });

            $(this).on("click", function (e) {
                $(".viewer-container").append('<div class="viewer-navigation"><a href="#" class="viewer-control prev"><i class="fas fa-chevron-left fa-2x"></i></a><a href="#" class="viewer-control next"><i class="fa fa-chevron-right fa-2x next"></i></a></div>')
            });

            $(document).on("click", ".viewer-control.prev", function (e) {
                e.preventDefault();
                viewer.prev();
            });

            $(document).on("click", ".viewer-control.next", function (e) {
                e.preventDefault();
                viewer.next();
            });

            
        });
    }

    // collapse contactbar on click outside
    $(document).on("click", function (e) {
        if ($(e.target).closest("#contactbar").length === 0) {
            $("#contactbar").removeClass("open");
            $("#contactbar .forms").addClass("hidden");
            $("#contactbar #buttons .btn").removeClass("active");
        }
    });

    // collapse contactbar
    $("#contactbar .collapse").on("click", function (e) {
        e.preventDefault();
        $("#contactbar").removeClass("open");
        $("#contactbar .forms").addClass("hidden");
        $("#contactbar #buttons .btn").removeClass("active");
    });

    // hide contactbar
    $("#contactbar .remove").on("click", function (e) {
        e.preventDefault();
        $("#contactbar").addClass("hidden");
        $.cookie('hideContactBar',true, {path: '/'});
    });

    // toggle open class on contactbar
    $("#contactbar #buttons > .btn").on("click", function (e) {
        // only add class if button not if chat button, which show zopim chat
        if(!$(this).hasClass("zopim")) {
            $(this).hasClass("active") ? $("#contactbar").removeClass("open") : $("#contactbar").addClass("open");
        }
    });
   
    $("#contactbar .showphone").on("click", function (e) {
        $("#contactbar .forms").not("#contactbar .phonelink").addClass("hidden");
        $("#buttons .btn").not(this).removeClass("active");

        $("#contactbar .phonelink").toggleClass("hidden");
        $(this).toggleClass("active");
    });

    $("#contactbar .callus").on("click", function (e) {
        $("#contactbar .forms").not("#contactbar .callusform").addClass("hidden");
        $("#buttons .btn").not(this).removeClass("active");

        $("#contactbar .callusform").toggleClass("hidden");
        $(this).toggleClass("active");
    });

    $("#contactbar .callme").on("click", function (e) {
        $("#contactbar .forms").not("#contactbar .callmeform").addClass("hidden");
        $("#buttons .btn").not(this).removeClass("active");

        $("#contactbar .callmeform").toggleClass("hidden");
        $(this).toggleClass("active");
    });

    $("#contactbar .mailus").on("click", function (e) {
        $("#contactbar .forms").not("#contactbar .mailusform").addClass("hidden");
        $("#buttons .btn").not(this).removeClass("active");

        $("#contactbar .mailusform").toggleClass("hidden");
        $(this).toggleClass("active");
    });

    if ($("#contactbar .contourMessageOnSubmit").length) {
        $("#contactbar .contourMessageOnSubmit").parent(".forms").toggleClass("hidden");
    }

    //Countdown
    $('[data-countdown]').each(function () {
        var $this = $(this), finalDate = $(this).data('countdown');
        $this.countdown(finalDate, function (event) {
            $this.html(event.strftime('<span class="time">%D</span> <span class="time">%H</span> <span class="time">%M</span> <span class="time">%S</span>'));
        });
    });
    
    // fix issue when position is absolute/fixed: http://stackoverflow.com/a/31811884
    $('.widget-tabs > a[data-toggle="tooltip"], a.widget-collapse[data-toggle="tooltip"]').tooltip({
        trigger: 'hover',
        container: 'body'
    });	

    // widget favorites
	$("#widget_tab_favorites").on('click', function (e) {
	    e.preventDefault();

	    var $this = $(this);
	    $this.siblings().removeClass('selected');
	    $this.addClass('selected');

	    var body = $("body");
	    var container = $(".widget-favorites .widget-tabs-box");
	    if (!container.hasClass("open")) {
	        container.addClass("open");
	    }
	    if (!body.hasClass("open-sidebar")) {
	        body.addClass("open-sidebar");
	    }

	    $(".widget-tabs-item").eq(0).hide();
	    $(".widget-tabs-item").eq(1).show();
	});

    // history/favorites close 
	$(".widget-tabs-box > a.close").on('click', function (e) {
	    e.preventDefault();

	    var $this = $(this);
	    $this.parent().removeClass('open');
	    $("body").removeClass("open-sidebar");
    });

    $(".widget-favorites > a.widget-collapse").on('click', function (e) {
        e.preventDefault();

        var $this = $(this),
            $parent = $this.parent();

        $parent.toggleClass('collapsed');

        var titleHide = $this.data("title-hide"),
            titleShow = $this.data("title-show");

        if ($parent.hasClass('collapsed')) {
            $this.attr("title", titleShow).tooltip('fixTitle').tooltip('show');
        }
        else {
            $this.attr("title", titleHide).tooltip('fixTitle').tooltip('show'); 
        }

    });

	$("#frontslider").carousel();

	$('#site').on("swiperight",'.carousel', function(){ 
		$(this).carousel('prev');
	});
	$('#site').on("swipeleft",'.carousel', function(){ 
		$(this).carousel('next');
	});

	$("#search button").click(function (e) {
	    if (!$("#search").hasClass("open")) {
	        e.preventDefault();
	        $("#search").addClass("open");
	        $("body").addClass("search-open");
	    }

	    $('.search-open').on('click', function (e) {
	        if (e.target !== $("#search") && !$("#search").find(e.target).length) {
	            $("#search").removeClass("open");
	            $("body").removeClass("search-open");
	        }
	    });
	});

	

    // weather forecast show hide on smartphone
	$(".collapsed-days .collapsed").click(function () {
	    $(".collapsed-days .weather-card").addClass("collapsed");
	    $(this).removeClass("collapsed");
	});
    

	$(".chat").click(function () {
	    var chatUrl = $(this).data("url");
	    if (chatUrl != "") {
	        window.open(chatUrl, '', 'resizable=yes,location=no,menubar=no,scrollbars=no,status=no,toolbar=no,fullscreen=no,dependent=no,width=650,height=650,left=100,top=100');
	    }
	}); 

	setTimeout(function(){
		$('#travelsearch').affix({
			offset: {
				top: function () {
                    var affixHeight = -1;

                    if ($(".frontPage").length) {
                        if ($('#front-video').length) {
                            affixHeight = $('#front-video').height() - $('.navbar').height() - $('#travelsearch').height();
                        } else {
                            affixHeight = $('#frontslider').height() - $('.navbar').height() - $('#travelsearch').height();
                        }
                    }

                    if ($(".landingpage").length && $(".landing-background").length) {
                        affixHeight = $(".landing-background").height() - $('.navbar').height();
                    }

                    if ($(".landingpageSlider").length && $("#frontslider").length) {
                        affixHeight = $("'#frontslider").height() - $('.navbar').height();
                    }

                    return (affixHeight)					
				}
			}
		});
		if( $(document).scrollTop() > $("frontslider").height()){
			$("#payhere").addClass("scroll");
		} else {
			$("#payhere").removeClass("scroll");
		}

		var viewportWidth = viewport().width,
		    tooltipTrigger = $("#travelsearch").find('.form-children > label');

        // on init
		if (!$("#travelsearch").is(".affix") && $("body").hasClass("forside")) {
		    tooltipTrigger.on('show.bs.tooltip', function () {
		        tooltipTrigger.data('bs.tooltip').options.placement = 'top';
		    });

		    tooltipTrigger.on('shown.bs.tooltip', function () {
		        $(this).next('.tooltip').css('top', -35 + 'px').css("cursor", "pointer");
		    });
		}
		else {
		    if (viewportWidth > 768) {
		        tooltipTrigger.on('show.bs.tooltip', function () {
		            tooltipTrigger.data('bs.tooltip').options.placement = 'bottom';
		        });

		        tooltipTrigger.on('shown.bs.tooltip', function () {
		            $(this).next('.tooltip').css('top', 85 + 'px').css("cursor", "pointer");
		        });
		    }  
		}

        // change tooltip position in mobile search
		$("#mobileTravelToggle").on('click', function () {
		    tooltipTrigger.on('show.bs.tooltip', function () {
		        tooltipTrigger.data('bs.tooltip').options.placement = 'top';
		    });

		    // only show/refresh when tooltip already is visible
		    if (tooltipTrigger.next('div.tooltip').is(':visible')) {
		        tooltipTrigger.tooltip('show');
		    }
		});

		$("#travelsearch").on('affixed-top.bs.affix', function () {
		    // http://stackoverflow.com/a/19320293

		    tooltipTrigger.on('show.bs.tooltip', function () {
		        tooltipTrigger.data('bs.tooltip').options.placement = 'top';
		    });

		    tooltipTrigger.on('shown.bs.tooltip', function () {
		        $(this).next('.tooltip').css('top', -35 + 'px');
		    });

		    // only show/refresh when tooltip already is visible
		    if (tooltipTrigger.next('div.tooltip').is(':visible')) {
		        tooltipTrigger.tooltip('show');
		    }
		});

		$("#travelsearch").on('affix.bs.affix', function () {		    
		    // http://stackoverflow.com/a/19320293
		    
		    tooltipTrigger.on('show.bs.tooltip', function () {
		        tooltipTrigger.data('bs.tooltip').options.placement = 'bottom';
		    });

		    tooltipTrigger.on('shown.bs.tooltip', function(){
		        $(this).next('.tooltip').css('top', 85 + 'px');
		    });

		    // only show/refresh when tooltip already is visible
		    if (tooltipTrigger.next('div.tooltip').is(':visible')) {
		        tooltipTrigger.tooltip('show');
		    }
		});

	    // hide tooltip on click
		$(document).on("click", 'div.tooltip', function () {
		    $(this).tooltip('hide');
		});

	}, 500);

	if ($(window).width() >= 992) {
	    setTimeout(function () {
	        var mainContainerInner = $(".maincontainer > .container").first();

	        if ($('#travelsearch.affix .alert').is(':visible')) {
	            var searchAlertHeight = $('#travelsearch.affix .alert').outerHeight();
	            mainContainerInner.css("margin-top", searchAlertHeight + "px");
	        } else {
	            mainContainerInner.css("margin-top", "");
	        }

	        $("#travelsearch select[name=transport]").on("change", function () {
	            if ($('#travelsearch.affix .alert').is(':visible')) {
	                var searchAlertHeight = $('#travelsearch.affix .alert').outerHeight();
	                mainContainerInner.css("margin-top", searchAlertHeight + "px");
	            } else {
	                mainContainerInner.css("margin-top", "");
	            }
	        });

	        // reset margin top, when closing alert
	        $('#travelsearch.affix .alert button.close').on("click", function () {
	            mainContainerInner.css("margin-top", "");
	        });

	    }, 500);
	}

	var lastScrollTop = 0;
	function travelScroll(){
        if ($(window).width() > 991) {
            if ($(this).scrollTop() > 100) {
                $('.navbar').addClass("solid");
            } else {
                $('.navbar').removeClass("solid");
            }

			var st = $(this).scrollTop();
			if (st > lastScrollTop && $(this).scrollTop() > 100){
				//DOWN
			    $('.navbar').addClass("scrolled");
			    $('#travelsearch-button').addClass("scrolled");
				$('#travelsearch').removeClass("nav-show");
			} else {
				//UP
			    $('.navbar').removeClass("scrolled");
			    $('#travelsearch-button').removeClass("scrolled");
				$('#travelsearch').addClass("nav-show");
			}
			lastScrollTop = st;
		}
	}

	$("body.hotel, body.parent-is-hotel").on("click", "#travelsearch-button", function () {
	    $(this).addClass("open");
	    $("#travelsearch").addClass("open");
	});

	$(window).scroll(function(event){
		travelScroll();
		if( $(document).scrollTop() > $("frontslider").height()){
			$("#payhere").addClass("scroll");
		} else {
			$("#payhere").removeClass("scroll");
		}
	});
    travelScroll();

	$('#hotelprice').on("change",'input:radio[name="pris_radio"]', function(){
		var currentId = $(this).attr('id');
		var price = $("label[for="+currentId+"]").html();
		$("#totalprice").html(price);	

		var idNumber = $(this).attr("id").substring(6,$(this).attr("id").length);
		bookLink = $("#link_" + idNumber).val();
	});

	$('.hotel-list').on("click",'input:radio[name^=radio_]', function(){
		var id = $(this).closest(".hotel").attr('id');
		var nr = $(this).attr('id').replace(id,"").replace("radio_","").replace("_","");

		$("#pris_ialt_"+id).html( $("#pris_ialt_"+nr+"_"+id+"_value").val() );

		if($("#pris_badge_"+nr+"_"+id+"_value").val() != ""){
			$("#div_pris_badge_"+id).removeClass("hidden");
			$("#div_pris_badge_"+id).html($("#pris_badge_"+id).val());
		} else {
			$("#div_pris_badge_"+id).addClass("hidden");

		}

		if ($("#pris_badge1500_" + nr + "_" + id + "_value").length) {
		    $("#" + id + " .badge1500").removeClass("hide");
		    if ($("#pris_badge1500_" + nr + "_" + id + "_value").val() == "0") {	        
		        $("#" + id + " .badge1500").addClass("hide");
		    } 
		}

		if ($("#pris_before_" + nr + "_" + id + "_value").val() != "") {
			$("#pris_ialt_"+id).closest("div").find(".before").removeClass("hidden");
			$("#pris_ialt_" + id).closest("div").find(".before").html($("#pris_before_" + nr + "_" + id + "_value").val());
			if (!$("#pris_ialt_" + id).hasClass("offer")) {
			    $("#pris_ialt_" + id).addClass("offer");
			}
			
		} else {
		    $("#pris_ialt_" + id).closest(".row").find(".before").addClass("hidden");
		    $("#pris_ialt_" + id).removeClass("offer");
		}

		if ($("#pris_spar_" + nr + "_" + id + "_value").val() != "") {
		    $("#pris_ialt_" + id).closest("div").find(".save").removeClass("hidden");
		    $("#pris_ialt_" + id).closest("div").find(".save").html($("#pris_spar_" + nr + "_" + id + "_value").val() + "<br>");
		} else {
		    $("#pris_ialt_" + id).closest(".row").find(".save").addClass("hidden");
		}

		$("#pris_org_"+id).html( $("#pris_org_"+nr+"_"+id+"_value").val() );
		$("#pris_gennemsnit_"+id).html( $("#pris_gennemsnit_"+nr+"_"+id+"_value").val() );
		$("#pris_info_stk_"+id).html( $("#pris_info_stk_"+nr+"_"+id+"_value").val() );
		$(".popBoxIncludes_"+id).data("html", "#priceIncludes_"+nr+"_"+id);

		$(".prices_hotel_content_"+id).find(".data_right.error").remove();
		$(".prices_hotel_content_"+id).find(".data_right.hide").removeClass('hide');
	});

	$('#hotelprice').on("click",'.totaltext .btn',function(){
		if(bookLink == ""){
			var idNumber = $('#hotelprice input:radio[name="pris_radio"]:checked').attr("id").substring(6,$('#hotelprice input:radio[name="pris_radio"]:checked').attr("id").length);
			bookLink = $("#link_" + idNumber).val();
            linket = bookLink;
		} else {
			linket = bookLink;
		}

		bruger_bredde = window.screen.width;
		bruger_hojde = window.screen.height;
		mvenstre = 10;
		mtop = 10;

		bredde = parseInt(bruger_bredde*0.9);
		hojde = parseInt(bruger_hojde*0.9)-120;

		if (bredde > 1200){
			bredde = 1200;
			mvenstre = (bruger_bredde-1200)/2;
		}
		if (bredde < 1000) bredde = 1000;
		if (hojde < 700) hojde = 700;

		if (bruger_hojde > 1000) mtop = 40;
		book = window.open(linket,"book","width="+bredde+",height="+hojde+",left="+mvenstre+",top="+mtop+",scrollbars=1,resizable=1,toolbar=0,menubar=0,status=0");

		book.focus();
	});

	$('#hotel-search').on("click",'.hotelsearchtotal a.book-button',function(e){
	    var bookBtn = $(this);
		if (bookBtn.attr("href") == undefined || bookBtn.attr("href") == "") {
		    e.preventDefault();
		    var bookUrl = $('#hotel-search input:radio:checked').data("bookurl");
		    window.open(bookUrl, "_self").focus();
		}
	});

	$('.social.share').on("click",'a',function(e){
		e.preventDefault();
		var shareLink = $(this).attr("href");
		window.open(shareLink,"_blank","width=640,height=440,location=no,scrollbars=no,menubar=no,status=no,top=0,left=0").focus();
	});

    function updateFavoriteHeart() {
        var cookie = $.cookie('favorit');
        if (cookie && cookie.length > 4) {
            $("#comparison > a > i").addClass("fas").removeClass("far");
        } else {
            $("#comparison > a > i").removeClass("fas").addClass("far");
        }
    }

    updateFavoriteHeart();

    $(document).on("click", '.hotel-list .favorite-wrapper, .hotel-page .favorite-wrapper', function(e){

        var hotelElem = $(this).closest(".hotel"),
            favoriteElem = hotelElem.find(".favorite-wrapper");

        var favoriteLabel = favoriteElem.find(".favoritelabel");
        var hotelCode = hotelElem.data('hotelcode');

        var textAdded = favoriteLabel.data("added"),
            textNotAdded = favoriteLabel.data("notadded");

        var cookieValue = $.cookie('favorit');
        favoriteElem.find(".favorite").toggleClass("fas").toggleClass("far");

        if (!cookieValue) {
			cookieValue = hotelCode + "-";
            if (favoriteLabel) {
                favoriteLabel.text(textAdded);
			}
        }
        else {
			if (cookieValue.indexOf(hotelCode) >= 0) {
                cookieValue = cookieValue.replace(hotelCode + "-", "");
                if (favoriteLabel) {
                    favoriteLabel.text(textNotAdded);
				}
			} else {
				cookieValue = cookieValue + hotelCode + "-";
                if (favoriteLabel) {
                    favoriteLabel.text(textAdded);
				}
			}
		}

		$.cookie('favorit', cookieValue, { expires: 365, path: '/' });

		updateFavoriteHeart();
	});

    if ($(".favoritelabel").length)
    {
        var cookieValue = $.cookie('favorit');
        if (cookieValue)
        {
            if (cookieValue.indexOf($(".hotel").data('hotelcode')) >= 0)
            {
				$(".favorite").addClass("fas").removeClass("far");
				$(".favoritelabel").text($(".favoritelabel").data("added"));
			}
		}
	}

	/*Hide tabs if screen is too small*/
	realignTabs();
	var windowTimer = window.setTimeout(function() {}, 0);
	$(window).on('resize', function() {
		window.clearTimeout(windowTimer);
		timer = window.setTimeout(function() {
			realignTabs();
		}, 250);
	});

	/*
	if(!$("#frontBottom").length){
		travelScroll();
	}
	*/

	if ($("#destsort").length) {
		sortDestinations();
	}
	$('#destsort input').bind('click', function() {
		sortDestinations();
	});

	//Løser problemer i forhold til slimmage og skjulte billeder der kaldes frem

	$('.carousel').on('slide.bs.carousel', function (e) {
		//For at slideren ikke kan "klaske" op, når billederne loades
		$(this).height($(this).height());
	});


	$( window ).resize(function() {
		$('.carousel').removeAttr("style")
	});


	//Initialize tooltips
	//$('[data-toggle="tooltip"]').tooltip()
	$('.hotel-rating[data-toggle="tooltip"], .label[data-toggle="tooltip"], #comparison .fa[data-toggle="tooltip"]').tooltip();

	$('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
		var scrollY = $(document).scrollTop();
		parent.location.hash = $(this).attr("href");
		window.scrollTo(0, scrollY);
	});


	// Image hover effect
    $(".imghover").hover(function () {

        $("source", this).each(function () {
            var imgSrc = $(this).attr("srcset");
            var imgHover = $(this).attr("data-hover");

            if (imgHover != "") {
                $(this).attr("srcset", imgHover);
                $(this).attr("data-hover", imgSrc);
            }
        });

        
        var imgHover = $("img", this).attr("data-hover");
        var imgSrc = $("img", this).attr("src");
        if (imgHover != "") {
            $("img", this).attr("src", imgHover);
            $("img", this).attr("data-hover", imgSrc);
        }

		
    }, function () {
        $("source", this).each(function () {
            var imgSrc = $(this).attr("srcset");
            var imgHover = $(this).attr("data-hover");

            if (imgHover != "") {
                $(this).attr("srcset", imgHover);
                $(this).attr("data-hover", imgSrc);
            }
        });

		var imgSrc = $("img",this).attr("src");
		var imgHover = $("img",this).attr("data-hover");
		if(imgHover != ""){
			$("img",this).attr("src",imgHover);
			$("img",this).attr("data-hover",imgSrc);
		}
	});


	var deviceAgent = navigator.userAgent.toLowerCase();
	var touch = ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch; //Modernizr.touch
	var isTouchDevice = touch &&
        (deviceAgent.match(/(iphone|ipod|ipad)/) ||
        deviceAgent.match(/(android)/) ||
        deviceAgent.match(/(iemobile)/) ||
        deviceAgent.match(/iphone/i) ||
        deviceAgent.match(/ipad/i) ||
        deviceAgent.match(/ipod/i) ||
        deviceAgent.match(/blackberry/i) ||
        deviceAgent.match(/bada/i));

	if (isTouchDevice) {
	    // Touch events are supported

	    // follow link to page, when dropdown menu / sub menu is open
	    $('#mobileMenu li.dropdown a.dropdown-toggle, #navbar li.dropdown a.dropdown-toggle').on('click', function (e) {
	        e.preventDefault();
	        e.stopPropagation();

	        var $this = $(this);
	        var $parent = $this.parent();
	        $parent.siblings().removeClass('open');

	        var stateOpen = $parent.hasClass('open');

	        //setTimeout(function () { 
	        // only toggle submenu when submenu not is open,
	        // otherwise follow link to page
	        if (stateOpen) {
	            window.location.href = $this.attr("href");
	        } else {
	            $parent.toggleClass('open');
	        }
	        //}, 0);
	    });
	}

	// Megamenu dynamisk indhold
	$(".dropdown.yamm-fw").hover(function() {
		$(this).find(".yamm-content").find(".dynamic-menu-content").html("");
		var basicElement = $(this).find(".yamm-content").find(".dynamic-menu-content");

		var title = "";
		var image = "";
		var text = "";

		if ($(basicElement).data('image') || $(basicElement).data('image')) {
			title = "<span class='menu-header'>" + $(this).children(".dropdown-toggle").html() + "</span>";
		}

		if($(basicElement).data('image')){
			image = "<img src='" + basicElement.data('image') + "' class='img-responsive'>";		
		}

		if ($(basicElement).data('text')) {
			text = basicElement.data('text');
		}

		var html = image + title + text;
		$(this).find(".yamm-content").find(".dynamic-menu-content").html(html);
	});

	$(".yamm-content .menuitem").mouseover(function () {
		var title = "";
		var image = "";
		var text = "";

		if ($(this).data('image') || $(this).data('text')) {
			title = "<span class='menu-header'>" + $(this).html() + "</span>";
		}

		if($(this).data('image')){
			image = "<img src='" + $(this).data('image') + "' class='img-responsive'>";
		}

		if ($(this).data('text')) {
			text = $(this).data('text');
		}

		var html = image + title + text;
		$(this).closest(".yamm-content").find(".dynamic-menu-content").html(html);

	});


	// Hotel mærkning modals
    $(document).on("click",".hotel-list .hotelLabel, .hotel-page .hotelLabel", function(){
		var hotelId = $(this).data('id');
		$.ajax({
			url: "/?altTemplate=HotelMaerkning&id="+hotelId,
			success: function(result){
				$(".hotel-marking-modal").remove();
				$("body").append(result);
				$('.hotel-marking-modal').modal('show');
			}
		});
    });


    $('.hotel-list, .hotel-page').on("click",".own-hotel-bar .fa-info-circle, .ownHotel  .fa-info-circle", function(e){
        e.preventDefault();
		$('.own-hotel-modal').modal('show');
	});


	// Pistekort
    var $section = $('#pistemap');
    $section.find('.panzoom').panzoom({
        $zoomIn: $section.find(".zoom-in"),
        $zoomOut: $section.find(".zoom-out"),
        $reset: $section.find(".reset"),
        startTransform: 'scale(0.5)',
        increment: 0.5,
        minScale: 0.5,
        animate: true,
        contain: 'invert'
	}).panzoom('zoom');
	$section.find('.panzoom').panzoom().parent().on('mousewheel.focal', function(e){
		e.preventDefault();
		var delta = e.delta || e.originalEvent.wheelDelta;
		var zoomOut = delta ? delta < 0 : e.originalEvent.deltaY > 0;
		$section.find('.panzoom').panzoom().panzoom('zoom', zoomOut, {
			increment: 0.5,
			animate: true,
			minScale: 0.2,
			focal: e
		});
	});

	// Google Maps
	function showMarkers(mgr){
		mgr.show();
    }

	function hideMarkers(mgr){
		mgr.hide();
    }

	$('#info').click (function (){
		if($(this).is(':checked')){
            showMarkers(info);
		}else{
            hideMarkers(info);
		}
	});
	$('#kabinelift').click (function (){
		if($(this).is(':checked')){
            showMarkers(kabinelift);
		}else{
            hideMarkers(kabinelift);
		}
	});
	$('#slaebelift').click (function (){
		if($(this).is(':checked')){
            showMarkers(slaebelift);
		} else {
            hideMarkers(slaebelift);
		}
	});
	$('#stolelift').click (function (){
		if($(this).is(':checked')){
            showMarkers(stolelift);
		} else {
            hideMarkers(stolelift);
		}
	});
	$('#skole').click (function (){
		if($(this).is(':checked')){
            showMarkers(skole);
		} else {
            hideMarkers(skole);
		}
	});
	$('#udlejning').click (function (){
		if($(this).is(':checked')){
            showMarkers(udlejning);
		} else {
            hideMarkers(udlejning);
		}
	});
	$('#langrend').click (function (){
		if($(this).is(':checked')){
            showMarkers(langrend);
		} else {
            hideMarkers(langrend);
		}
	});
	$('#hotels').click (function (){
		if($(this).is(':checked')){
            showMarkers(hotels);
		} else {
            hideMarkers(hotels);
		}
	});
	$('#currenthotel').click (function (){
		if($(this).is(':checked')){
            showMarkers(currenthotel);
		}else{
            hideMarkers(currenthotel);
		}
	});
	$('#otherhotels').click (function (){
		if($(this).is(':checked')){
            showMarkers(otherhotels);
		}else{
            hideMarkers(otherhotels);
		}
	});

	var hash = document.location.hash;
    if (hash && $('.nav-tabs').length) {
        var hashLocation = $('.nav-tabs a[href=' + hash + ']');
        if (hashLocation.length){
            hashLocation.tab('show');
			initialize();
		}
	}	

	// Toggle mobile menu
	$("#mobileToggle").click(function(){

		$("#travelsearch").removeClass("open");
		$("#mobileTravelToggle").removeClass("open");

		$(this).toggleClass("open");
		$("html, body, #site").removeClass("open-travel-search").toggleClass("open-menu");
		$("#mobileMenu").toggleClass("open");
	});

	// Toggle mobile travel
	$(".toggleTravel").click(function(){

		$("#mobileToggle").removeClass("open");
		$("html, body, #site").removeClass("open-menu");
		$("#mobileMenu").removeClass("open");

		$("#travelsearch").toggleClass("open");
		$("#mobileTravelToggle").toggleClass("open");
		$("html, body, #site").toggleClass("open-travel-search");
	});

	// Readmore toggle
	$('#site').on("click",".readToggle", function(){
		$(this).closest(".readMoreContent").toggleClass("open");
		$(this).children(".fa").toggleClass("fa-arrow-up fa-arrow-right");
		$(this).children(".less").toggle();
        $(this).children(".more").toggle();
	});

	// Dropdowns
	$(".drop .dropHeader, .drop .dropFooter").click(function(){
		$(this).closest('.drop').children(".dropBody").toggle().toggleClass("open");
	});

	// Hotel Search
	$('.hotel-search').on("click",".paymentInfoShowHide", function(){
		$(".paymentInfo").toggleClass("hidden")
	});
	$('.hotel-search').on("click",".price-includes", function(){
		var modalId = $('#hotel-search input:radio:checked').attr("id").replace("radio_","priceIncludes_");
		var modalContent = $("#"+modalId).html();
		if(modalContent != undefined){
			$('#price-include-modal').find(".modal-body").html(modalContent);
			$('#price-include-modal').modal('show');
		}
	});
	$('.hotel-list').on("click",".price-includes", function(){
		var modalId = $(this).closest(".hotel").attr('id');
		var modalNr = "0";

		if($("[id^=radio_]").length){
			modalNr = $(this).closest(".hotel").find("input:radio:checked").attr("id").replace(modalId,"").replace("radio_","").replace("_","");
		} 
		var modalContent = $("#priceIncludes_"+modalNr+"_"+modalId).html();

		if(modalContent != undefined){
			$('#price-include-modal').find(".modal-body").html(modalContent);
			$('#price-include-modal').modal('show');
		}
	});
	$('.hotel-search').on("change","input", function(){
		getHotelBookingInfo($(this).prop('id'));
	});
	function getHotelBookingInfo(info){
		$("#totalprice").html($("#"+info).data("price"));
		$(".paymentInfo").html($("#"+info).data("priceinfo"));
		$(".price-person").text($("#"+info).data("priceperson"));
	}

	$('a.hotelpricebutton').click(function() {
		var target = $(this.hash);
		target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
		if (target.length) {
			$('html,body').animate({
				scrollTop: target.offset().top - parseInt($(".maincontainer").css("marginTop"))
			}, 1000);
			return false;
		}
	});

	// Umbraco Forms
	if($(".contour").length){

		var formId = $('input[name="FormId"]').val();
		var formName = $('input[name="FormName"]').val();
	}

    // Discount page - Open form modal on success
    if ($("body.discountlist .umbraco-forms-submitmessage").length) {
        var modalId = $(".umbraco-forms-submitmessage").closest(".modal").attr("id");
        $('#' + modalId).modal('show');
    }

    // Stop scroll icon animation on mousedown
    $('.mybooking').on("touchmove", ".table-responsive", function () {
        $(".scroll").removeClass("shake")
    });

});

function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }
    return { width: e[a + 'Width'], height: e[a + 'Height'] };
}

function listFavoriteCheck(){
	$(".hotel-list .hotel").each(function(){
		if($.cookie('favorit') != undefined){
			if($.cookie('favorit').indexOf($(this).data('hotelcode')) >= 0){
				$(this).find(".favorite").removeClass("fas").addClass("far");
			}
		}
	});
}

function sortDestinations(){
	var matchPercentage = 0;
	$('#destsort input:checked').each(function(index) {
		var datavar = $(this).attr("name").toLowerCase();
		var calcvar = "data-calc-" + $(this).attr("name").toLowerCase();
		var startvalue = $(this).val();
		$(".destination").each(function() {
			$(this).attr(calcvar,Math.abs($(this).data(datavar) - startvalue))
			var sumtotal =	parseInt($(this).attr("data-calc-beginners")) +
				parseInt($(this).attr("data-calc-experienced")) + 
				parseInt($(this).attr("data-calc-childfriendly")) + 
				parseInt($(this).attr("data-calc-activities")) + 
				parseInt($(this).attr("data-calc-afterski")) + 
				parseInt($(this).attr("data-calc-shopping")) + 
				parseInt($(this).attr("data-calc-restaurants")) +
				parseInt($(this).attr("data-calc-snowboarding")) +
				parseInt($(this).attr("data-calc-offpiste")) +
				parseInt($(this).attr("data-calc-coziness"));
			$(this).attr("data-calcsum",sumtotal)
			matchPercentage = Math.round(100 - ((100/30) * sumtotal));
			$(this).find(".match-amount").text(matchPercentage+" %").parent().removeClass("hidden");
		});
	});

	var ascending = false;
	var sorted = $('.destination-sort-list .destination').sort(function(a,b){
		return (ascending == ($(a).data('calcsum') < $(b).data('calcsum'))) ? 1 : -1;
	});
	ascending = ascending ? false : true;
	$('.destination-sort-list').html(sorted);
	$(".destination").each(function() {
		$(this).after("<hr>");
	});
}

function radio_select(radioId){
	var price = $("#radio_"+radioId).data("price");
	$(".hotelpricebutton .price").html(price);

    //update book button href
	var bookUrl = $('#hotel-search input:radio:checked').data("bookurl");
	$("#hotel-search .hotelsearchtotal .book-button").attr("href", bookUrl);
}

function realignTabs() {
	if($(".menu-tabs").length){
	    var totalWidth = $(".menu-tabs .nav-tabs").width();
	    var tabsWidth = 70;

	    // clone hidden dropdown to measure its width
	    var dropdownCopy = $(".menu-tabs .nav-tabs li.dropdown:first").clone()
                                .removeClass("hidden")
                                .css({ visibility: "hidden", display: "block", position: "absolute" });

	    $(".menu-tabs .nav-tabs").append(dropdownCopy);
	    var dropdownWidth = dropdownCopy.width();
	    if (dropdownWidth <= 0) {
            // set default dropdown width
	        dropdownWidth = 100;
	    }
	    dropdownCopy.remove();
		
		$(".menu-tabs .nav-tabs .dropdown-menu").empty();
		$(".menu-tabs .nav-tabs li[role='presentation']").each(function(index) {
			$(this).removeClass("hidden");
			tabsWidth = tabsWidth + $(this).width();

            // check if there is space for tabs and dropdown
			if (tabsWidth > totalWidth || (totalWidth - tabsWidth) < dropdownWidth) {
				$(this).clone().appendTo(".menu-tabs .nav-tabs .dropdown-menu");
				$(this).addClass("hidden");
			}
		});
		if (tabsWidth > totalWidth || (totalWidth - tabsWidth) < dropdownWidth) {
			$(".menu-tabs .nav-tabs .dropdown").removeClass("hidden");
		}else{
			$(".menu-tabs .nav-tabs .dropdown").addClass("hidden");
		}
	}
}

$(".my-booking, #welcome").on("click", ".logout", function (e) {
    e.preventDefault();

    $.removeCookie("mybooking", { path: '/' });
    window.location.href = this.href;

    return false;
});