/**
 * Truncate Filter
 * @Param text
 * @Param length, default is 10
 * @Param end, default is "..."
 * @return string
 */

/* 
    Usage:
    <p>{{myText|truncate}}</p>
    <p>{{myText|truncate:5}}</p>
    <p>{{myText|truncate:25:" ->"}}</p>

    http://www.angularjs4u.com/filters/angularjs-truncate-text-filter-examples/
*/

angular.module("travelsearch.filters").filter('truncate', function () {
    return function (text, length, end) {
        if (isNaN(length))
            length = 10;

        if (end === undefined)
            end = "...";

        if (text.length <= length || text.length - end.length <= length) {
            return text;
        }
        else {
            return String(text).substring(0, length-end.length) + end;
        }
    };
});