var app = angular.module("travelsearch.core");

app.requires.push('angular.filter');
app.requires.push('ngPromiseExtras');

app.controller('MyBooking.Controller', ['$scope', '$rootScope', '$timeout', '$filter', '$q', '$sce', 'bookingResource', 'utilsService', 'cookieService', 'errorHandler', function ($scope, $rootScope, $timeout, $filter, $q, $sce, bookingResource, utilsService, cookieService, errorHandler) {
    var vm = this;

    vm.orderTotal = 0;

    vm.order = null;
    vm.loading = true;
    vm.loadingPayments = true;
    vm.loadingPaymentsError = false;

    vm.error = false;
    vm.errorType = null;

    vm.pickupLocations = null;
    vm.pickupLocationChanged = false;
    vm.startDayChanged = false;

    vm.payments = [];
    vm.additionalServices = [];
    vm.availableServices = [];
    vm.changedServices = [];

    vm.showLogin = true;
    vm.successOrderUpdate = false;
    vm.transaction = null;
    vm.serviceEditMode = false;
    vm.showParticipantBirthdateAlert = false;

    vm.initService = initService;
    vm.initStartDays = initStartDays;
    vm.serviceChosenChange = serviceChosenChange;
    vm.selectUnit = selectUnit;
    vm.selectService = selectService;
    vm.selectStartDay = selectStartDay;
    vm.getServiceInfo = getServiceInfo;

    vm.serviceinfo = {};

    vm.submitLogin = submitLogin;
    vm.submitOrder = submitOrder;
    vm.submitPayment = submitPayment;
    vm.submitCustomPayment = submitCustomPayment;
    vm.changePickupLocation = changePickupLocation;

    vm.updateParticipantBirthdate = updateParticipantBirthdate;

    vm.ticketMailState = {};
    vm.printTicket = printTicket;
    vm.sendTicket = sendTicket;

    vm.addDays = addDays;
    vm.capitalize = capitalize;

    //vm.isServiceSelected = isServiceSelected;
    vm.calculateServiceGroupTotalPrice = calculateServiceGroupTotalPrice;

    vm.filterParticipantService = filterParticipantService;


    init();

    function init() {

        var orderId = null;
        var pincode = null;
        var bookingNo = null;

        if (utilsService.getParameterByName('orderid')) {
            orderId = utilsService.getParameterByName('orderid');
        }
        if (utilsService.getParameterByName('order_id')) {
            orderId = utilsService.getParameterByName('order_id');
        }

        if (utilsService.getParameterByName('pincode')) {
            pincode = utilsService.getParameterByName('pincode');
        }
        if (utilsService.getParameterByName('pin_code')) {
            pincode = utilsService.getParameterByName('pin_code');
        }

        if (utilsService.getParameterByName('bookingno')) {
            bookingNo = utilsService.getParameterByName('bookingno');
        }
        if (utilsService.getParameterByName('booking_no')) {
            bookingNo = utilsService.getParameterByName('booking_no');
        }

        if ((orderId || bookingNo) && pincode) {
            var bookId = orderId;
            if (bookingNo) {
                bookId = bookingNo;
            }

            getOrder(bookId, pincode);

        } else {
            var orderInfo = cookieService.getCookieData("mybooking");

            if (!angular.isUndefined(orderInfo) && orderInfo !== null) {
                try {
                    if (orderInfo.id && orderInfo.pincode) {
                        getOrder(orderInfo.id, orderInfo.pincode);
                    }
                }
                catch (err) {
                    vm.error = true;
                    vm.loading = false;
                }
            } else {
                vm.loading = false;
                vm.showLogin = true;
            }
        }
    }

    function getOrder(bookId, pincode) {

        bookingResource.getOrder(bookId, pincode).then(function (response) {

            var data = response.data;

            if (response.status !== 200) {
                console.warn("Missing data for booking", response.status, response.statusText);
                vm.error = true;
                vm.errorType = response.status;
                vm.errorResponse = response.statusText;

                vm.showLogin = true;
            } else {
                if (data.order_status.toLowerCase() !== "booking") {
                    vm.error = true;
                    vm.errorResponse = response.statusText;
                    vm.errorType = "InvalidBooking";

                    vm.showLogin = true;
                } else {
                    // Extend order with additional properties

                    vm.order = resolve(data);
                    vm.order = adjustServiceData(data);
                    vm.orderTotal = vm.order.total_amount;

                    angular.forEach(vm.order.participants, function (participant) {
                        participant.potentialServices = [];
                        participant.services = $filter('orderBy')(participant.services, 'selected', true);

                        participant.totalPrice = participant.total_price_for_participant

                        if (!participant.birthdate) {
                            participant.hasBirthdate = false;
                            vm.showParticipantBirthdateAlert = true;
                        } else {
                            participant.birthdate = new Date(participant.birthdate);
                            participant.hasBirthdate = true;
                        }

                        angular.forEach(participant.services, function (service) {
                            // Find unique services for participant
                            var hasUniqueService = participant.potentialServices.map(function (x) {
                                return x.service_group_id;
                            }).indexOf(service.service_group_id);
                            if (hasUniqueService === -1) {
                                participant.potentialServices.push(service);
                            }

                            // Find unique services
                            var hasAvailableService = vm.availableServices.map(function (x) {
                                return x.service_group_id;
                            }).indexOf(service.service_group_id);
                            if (hasAvailableService === -1) {
                                vm.availableServices.push(service);
                            }
                        });

                        // Sort by service_group_sort  
                        participant.potentialServices = $filter('orderBy')(participant.potentialServices, 'service_group_sort');
                    });

                    // Sort by service_group_sort                    
                    vm.availableServices = $filter('orderBy')(vm.availableServices, 'service_group_sort');
                    
                    // Set active to first service group
                    var firstChangableService = $filter("filter")(vm.availableServices, { web_change: 1 }, true)[0];

                    if (!!firstChangableService) {
                        vm.activeServiceGroupId = firstChangableService.service_group_id;
                        getServiceInfo();
                    }
                    
                    getPayments(data.id, data.pin_code);

                    var obj = {
                        id: bookId,
                        pincode: pincode,
                        firstname: vm.order.customer.first_name
                    };

                    cookieService.setCookieData("mybooking", obj);

                    vm.showLogin = false;

                    watchOrderChange(vm.order);
                }               
            }
            vm.loading = false;
        })
        .catch(function (response) {
            console.error("Error fetching booking", response.status, response.statusText);

            errorHandler.handleError(response);

            vm.error = true;
            vm.errorType = response.status;
            vm.loading = false;
        });
    }

    function getPayments(orderId, pincode) {
        bookingResource.getPayments(orderId, pincode).then(function (response) {

            var data = response.data;

            if (response.status !== 200) {
                console.warn("Missing data for payments", response.status, response.statusText);
            }

            vm.payments = data;

            vm.loadingPayments = false;
        })
        .catch(function (response) {
            console.error("Error fetching payments", response.status, response.statusText);

            vm.loadingPaymentsError = true;
            vm.loadingPayments = false;
        });
    }

    function adjustServiceData(order) {
        //Set "chosen" property on service, so we know the original value and can use "selected" property.
        //It then doesn't disable checkbox immediately after selection, because disabled state is binded to this property.

        angular.forEach(order.participants, function (obj) {
            angular.forEach(obj.services, function (value, key) {
                value.chosen = value.selected > 0;
            });
        });

        return order;
    }

    function initService(serviceGroupOptions) {

        if (serviceGroupOptions.length === 0)
            return;

        // Filter to selected or included
        var filteredServices = serviceGroupOptions.filter(function (obj) {
            return obj.selected == 1 || obj.included == 1;
        });

        // Filter included
        var includedServices = $filter("where")(filteredServices, { included: 1 });

        // Filter selected
        var selectedServices = $filter("where")(filteredServices, { selected: 1 });

        // Find default selected
        var defaultSelected = selectedServices[0] || includedServices[0];

        if (defaultSelected) {
            // Set defaults
            serviceGroupOptions[0].serviceIsSelected = true;
            serviceGroupOptions[0].selectedUnits = defaultSelected.number_of_units;

            angular.forEach(serviceGroupOptions, function (obj) {
                obj.selectedService = defaultSelected.service_price_id;
                obj.disabled = parseFloat(obj.service_price) < parseFloat(defaultSelected.service_price);
            });
        }
        else {
            // Set defaults
            serviceGroupOptions[0].serviceIsSelected = false;
            serviceGroupOptions[0].selectedUnits = "";
        }

        // Set included
        serviceGroupOptions[0].serviceIsIncluded = includedServices.length > 0 ? true : false;

        // Set disabled on init if service is selected (included or selected)
        serviceGroupOptions[0].serviceIsDisabled = serviceGroupOptions[0].serviceIsSelected;
    }

    function serviceChosenChange(orderId, participant, serviceGroupId, servicePriceId, chosen) {
        vm.successOrderUpdate = false;

        var data =
        {
            orderId: orderId,
            participantId: participant.participant_id,
            serviceGroupId: serviceGroupId,
            servicePriceId: servicePriceId
        };

        if (chosen === false && data.participantId && data.servicePriceId)
        {
            // Find existing service in group
            var participantServices = participant.services || [];
            var prevService = participantServices.filter(function (obj) {
                return obj.service_group_id === parseInt(serviceGroupId) && obj.selected == 1;
            })[0];

            // Get new service to use
            var newService = participantServices.filter(function (obj) {
                return obj.service_group_id === parseInt(serviceGroupId) && obj.service_price_id === parseInt(servicePriceId);
            })[0];

            // Mark the service as not selected
            if (newService) {
                newService.selected = 0;
            }

            var updatedService = {
                orderId: orderId,
                service: newService,
                participant: {
                    participant_id: participant.participant_id,
                    full_name: participant.full_name
                }
            };

            var expr = {
                service: {
                    service_group_id: parseInt(serviceGroupId),
                    service_price_id: parseInt(servicePriceId)
                },
                participant: {
                    participant_id: participant.participant_id
                }
            };

            // Check if exists in changed services
            var found = $filter('filter')(vm.additionalServices, expr, true)[0];

            // Check if exists in changed services
            var found2 = $filter('filter')(vm.changedServices, expr, true)[0];

            var ref = {
                orderId: data.orderId,
                prevService: prevService,
                service: updatedService.service,
                participant: {
                    participant_id: participant.participant_id,
                    full_name: participant.full_name
                }
            };

            // Add or update changed services
            if (!found2 && ref.service) {
                // Check if exists in array of additional services.
                if (found) {
                    // Remove from additional services
                    vm.additionalServices = removeFromArray(vm.additionalServices, found);
                }

                vm.changedServices.push(ref);
            }
            else {
                if (ref.service) {
                    // Update match in changed services
                    angular.forEach(vm.changedServices, function (obj) {
                        if (obj.orderId === ref.orderId &&
                            obj.participant.participant_id === ref.participant.participant_id &&
                            obj.service.service_group_id === ref.service.service_group_id) {
                            obj.service = ref.service;
                        }
                    });
                }
            }
        }
        else if (chosen === true)
        {
            var servicesInGroup = participant.services.filter(function (obj) {
                return obj.service_group_id === parseInt(serviceGroupId);
            });

            // Set selected service to empty string if none is selected.
            if (servicesInGroup && servicesInGroup.length > 0 && utilsService.isUndefinedOrNull(servicesInGroup[0].selectedService)) {
                servicesInGroup[0].selectedService = "";
            }

            // Find item marked as not selected
            var expr1 = {
                service: {
                    service_group_id: parseInt(serviceGroupId),
                    service_price_id: parseInt(servicePriceId)
                },
                participant: {
                    participant_id: participant.participant_id
                }
            };

            // Check if exists in additional services
            var item1 = $filter('filter')(vm.additionalServices, expr1, true)[0];
            if (item1) {
                vm.additionalServices = removeFromArray(vm.additionalServices, item1);
            }

            // Find item marked as not selected
            var expr2 = {
                service: {
                    service_group_id: parseInt(serviceGroupId),
                    service_price_id: parseInt(servicePriceId),
                    selected: 0
                },
                participant: {
                    participant_id: participant.participant_id
                }
            };

            // Check if exists in changed services
            var item2 = $filter('filter')(vm.changedServices, expr2, true)[0];
            if (item2) {
                vm.changedServices = removeFromArray(vm.changedServices, item2);
            }
        }

        var allChosenServices = vm.additionalServices.concat(vm.changedServices);

        // Update total price as selected services change.
        vm.order.total_amount = updateOrderTotal(allChosenServices);
        
    }

    function selectUnit(serviceGroupId, services, unit) {
        // Reset selected service when changing unit
        services[0].selectedService = "";
    }

    function selectService(orderId, participant, service, serviceGroupId, servicePriceId) {
        vm.successOrderUpdate = false;
        
        if (service) {
            // Service as checkbox
            if (service.disabled)
                return;

            if (service.selected == 1) {
                var svcAdded = {
                    orderId: orderId,
                    service: service,
                    participant: {
                        participant_id: participant.participant_id,
                        full_name: participant.full_name
                    }
                };

                vm.additionalServices.push(svcAdded);
                

            }
            else {
                var expression = {
                    service: {
                        service_group_id: service.service_group_id
                    },
                    participant: {
                        participant_id: participant.participant_id
                    }
                };

                var additionalService = $filter('filter')(vm.additionalServices, expression, true)[0];
                if (additionalService) {
                    vm.additionalServices = removeFromArray(vm.additionalServices, additionalService);
                }
            }
        }
        else {
            // Service as dropdown

            // Find existing service in group
            var participantServices = participant.services || [];
            var prevService = participantServices.filter(function (obj) {
                return obj.service_group_id === parseInt(serviceGroupId) && obj.selected == 1;
            })[0];

            // Get new service to use
            var newService = participantServices.filter(function (obj) {
                return obj.service_group_id === parseInt(serviceGroupId) && obj.service_price_id === parseInt(servicePriceId);
            })[0];

            if (!newService) {
                return;
            }

            // Mark new service as selected
            if (newService) {
                newService.selected = 1;
            }

            var updatedService = {
                orderId: orderId,
                service: newService,
                participant: {
                    participant_id: participant.participant_id,
                    full_name: participant.full_name
                }
            };

            var expression2 = {
                service: {
                    service_group_id: parseInt(serviceGroupId)
                },
                participant: {
                    participant_id: updatedService.participant.participant_id
                }
            };

            // Check if exists in additional services
            var found = $filter('filter')(vm.additionalServices, expression2, true)[0];
            
            // If not service already is added
            if (!found && !prevService) {
                vm.additionalServices.push(updatedService);
            }
            else {

                var ref = {
                    orderId: orderId,
                    prevService: prevService,
                    service: updatedService.service,
                    participant: {
                        participant_id: participant.participant_id,
                        full_name: participant.full_name
                    }
                };

                var expression3 = {
                    service: {
                        service_group_id: parseInt(serviceGroupId)
                    },
                    participant: {
                        participant_id: participant.participant_id
                    }
                };

                // Check if exists in changed services
                var found2 = $filter('filter')(vm.changedServices, expression3, true)[0];

                // Add or update changed services
                if (!found2 && ref.service) {

                    // Check if exists in array of additional services.
                    if (found) {
                        // Update match in additional services
                        angular.forEach(vm.additionalServices, function (obj) {
                            if (obj.orderId === ref.orderId &&
                                obj.participant.participant_id === ref.participant.participant_id &&
                                obj.service.service_group_id === ref.service.service_group_id) {
                                obj.service = ref.service;
                            }
                        });
                    } else {
                        vm.changedServices.push(ref);
                    }
                }
                else {
                    if (ref.service) {
                        // Update match in changed services
                        angular.forEach(vm.changedServices, function (obj) {
                            if (obj.orderId === ref.orderId &&
                                obj.participant.participant_id === ref.participant.participant_id &&
                                obj.service.service_group_id === ref.service.service_group_id) {
                                obj.service = ref.service;
                            }
                        });
                    }
                }
            }

            //Update Potential Services
            var potentialService = $filter('filter')(participant.potentialServices, { service_group_id: serviceGroupId }, true)[0];
            participant.potentialServices = removeFromArray(participant.potentialServices, potentialService);
            participant.potentialServices.push(newService);
            participant.potentialServices = $filter('orderBy')(participant.potentialServices, 'service_group_sort');

            var servicesInGroup = participantServices.filter(function (obj) {
                return obj.service_group_id === parseInt(serviceGroupId);
            });

            angular.forEach(servicesInGroup, function (obj) {
                obj.selectedService = servicePriceId;
                obj.selected = servicePriceId === obj.service_price_id ? 1 : 0;
            });

            var filteredService = servicesInGroup.filter(function (obj) {
                return obj.service_price_id === parseInt(servicePriceId);
            })[0];

            if (filteredService) {
                setStartDays(servicesInGroup, filteredService);
            }
        }    

        var allChosenServices = vm.additionalServices.concat(vm.changedServices);
        // Update total price as selected services change.
        vm.order.total_amount = updateOrderTotal(allChosenServices);

        participant.totalPrice = updateParticipantTotal(participant)
    }

    function selectStartDay(orderId, participant, serviceGroupId, services, servicePriceId, startDay) {
        var orderInfo = cookieService.getCookieData("mybooking");

        var data =
        {
            orderId: orderId,
            pinCode: orderInfo.pinCode,
            participantId: participant.participant_id,
            serviceGroupId: serviceGroupId,
            servicePriceId: servicePriceId,
            startDay: startDay
        };

        vm.startDayChanged = true;
    }

    function submitLogin(form) {

        var username = form.username.$modelValue;
        var password = form.password.$modelValue;

        if (form.$invalid) {
            return;
        }

        var obj = {
            id: username,
            pincode: password
        };

        getOrder(obj.id, obj.pincode);
    }

    function submitOrder(form) {

        if (form.$invalid) {

            var firstInvalid = angular.element("form[name='" + form.$name + "']").find('.ng-invalid:visible:first');
            if (firstInvalid) {
                firstInvalid.focus();
            }

            return;
        }

        vm.loading = true;

        var loopPromises = [];

        for (var p = 0; p < vm.order.participants.length; p++) {
            var participant = vm.order.participants[p];

            var currentPickupLocation = participant.departure_location_id || participant.return_location_id || null;

            var pData =
            {
                participant_id: participant.participant_id,
                full_name: participant.full_name,
                gender: participant.gender,
                age: participant.age,
                pickupLocation: parseInt(participant.pickup_location) || null
            };

            if (pData.pickupLocation !== currentPickupLocation)
            {
                var deferred1 = $q.defer();

                bookingResource.updateParticipant(vm.order.id, pData.participant_id, pData.full_name, pData.gender, pData.age, "", vm.order.pin_code, pData.pickupLocation).then(function (response) {
                    deferred1.resolve(response.data);
                }, function (error) {
                    deferred1.reject(error);
                });

                loopPromises.push(deferred1);
            }

            for (var s = 0; s < participant.services.length; s++) {
                var service = participant.services[s];

                var serviceGroupId = service.service_group_id;
                var servicePriceId = service.service_price_id;

                var servicesInGroup = participant.services.filter(function (obj) {
                    return obj.service_group_id === parseInt(serviceGroupId);
                });

                var startDay = servicesInGroup && servicesInGroup[0].selectedStartDay !== null ? servicesInGroup[0].selectedStartDay : null;

                // If service already is selected and startday is the same, we don't need to continue.
                if (service.chosen && service.startday === startDay) {
                    continue;
                }

                var data =
                {
                    orderId: vm.order.id,
                    pinCode: vm.order.pin_code,
                    participantId: participant.participant_id,
                    selected: service.selected,
                    serviceGroupId: serviceGroupId,
                    servicePriceId: servicePriceId,
                    startDay: startDay
                };

                // If service has been selected then attach it.
                if (data.selected)
                {
                    var deferred2 = $q.defer();

                    bookingResource.attachService(data.orderId, data.participantId, data.serviceGroupId, data.servicePriceId, data.pinCode, data.startDay).then(function (response) {
                        deferred2.resolve(response.data);
                    }, function (error) {
                        deferred2.reject(error);
                    });

                    loopPromises.push(deferred2);
                }
            }
        }

        for (var t = 0; t < vm.changedServices.length; t++) {
            var cs = vm.changedServices[t];

            var d =
            {
                orderId: vm.order.id,
                pinCode: vm.order.pin_code,
                prevServicePriceId: cs.prevService ? cs.prevService.service_price_id : null,
                participantId: cs.participant.participant_id,
                serviceGroupId: cs.service.service_group_id,
                servicePriceId: cs.service.service_price_id,
                selected: cs.service.selected,
                startDay: null
            };

            var part = $filter("filter")(vm.order.participants, { participant_id: d.participantId }, true)[0];

            if (part) {
                var pServicesInGroup = part.services.filter(function (obj) {
                    return obj.service_group_id === parseInt(d.serviceGroupId);
                });

                if (pServicesInGroup) {
                    d.startDay = pServicesInGroup[0].selectedStartDay;
                }
            }
            // Ensure we have a service group id and price id.
            if (!d.serviceGroupId || !d.servicePriceId)
                continue;

            // If selected service is the same as already selected and marked as selected.
            var sameService = d.prevServicePriceId === d.servicePriceId;
            if (sameService && cs.service.selected == 1)
                continue;

            if (d.prevServicePriceId || d.selected == 0)
            {
                var def1 = $q.defer();

                // Detach previous service
                bookingResource.detachService(d.orderId, d.participantId, d.servicePriceId, d.pinCode).then(function (response) {
                    def1.resolve(response.data);
                }, function (error) {
                    def1.reject(error);
                });

                loopPromises.push(def1);
            }

            // Only attach if marked as selected
            if (d.selected == 1) {
                var def2 = $q.defer();

                // Attach updated service
                bookingResource.attachService(d.orderId, d.participantId, d.serviceGroupId, d.servicePriceId, d.pinCode, d.startDay).then(function (response) {
                    def2.resolve(response.data);
                }, function (error) {
                    def2.reject(error);
                });

                loopPromises.push(def2);
            }
        }
        

        // Creating an empty initial promise that always resolves itself.
        var promise = $q.all([]);

        // Iterating list of promises.
        angular.forEach(loopPromises, function (item) {
            promise = promise.then(function () {
                return $timeout(function () {
                }, 0);
            });
        });

        promise.finally(function () {
            vm.successOrderUpdate = true;
            vm.loading = false;
        });
    }

    function submitPayment(form, amount) {

        amount = amount || 0;

        if (form.$invalid || amount <= 0) {
            return;
        }

        var gateway = angular.element("#my-booking").data("gateway");

        bookingResource.requestPayment(vm.order, amount, vm.order.order_payment_identification, gateway).then(function (response) {
            var data = response.data;
            window.location.href = data;
        })
        .catch(function (data) {

        });
    }

    function submitCustomPayment(form) {

        if (form.$invalid) {
            return;
        }

        var amount = !angular.isUndefined(form.paymentAmount) && form.paymentAmount !== null ? form.paymentAmount.$modelValue : null;
        if (!amount) {
            return;
        }

        var gateway = angular.element("#my-booking").data("gateway");

        bookingResource.requestPayment(vm.order, amount, vm.order.order_payment_identification, gateway)
            .then(function (response) {
                var data = response.data;

                if (gateway == "quickpay") {
                    window.location.href = data;
                } else {
                    window.location.href = data;
                }
            })
            .catch(function (data) {

            });
    }

    function updateParticipantTotal(participant) {
        
        var services = $filter("filter")(participant.services, { selected: 1 }, true);
        var servicesTotal = services.reduce(function (a, b) {
            var servicePrice = b.service_price;
            if (b.included == 1) {
                servicePrice = b.service_order_price;
            }
            return parseFloat(a) + parseFloat(servicePrice);
        }, 0);
        return parseFloat(participant.package_price, 10) + parseFloat(servicesTotal, 10);
    }

    function updateParticipantBirthdate(participant) {
        if (isDate(participant.birthdate)) {
            var min = new Date(1929, 11, 31);
            var max = new Date().setHours(0, 0, 0, 0);
            var birthdate = new Date(participant.birthdate);

            if (birthdate > min && birthdate < max) {
                bookingResource.updateParticipant(vm.order.id, participant.participant_id, participant.full_name, participant.gender, participant.age, birthdate, vm.order.pin_code, participant.pickupLocation).then(function (response) {
                });
                participant.dateNotValid = false;
                participant.dateNotAllowed = false;
            } else {
                participant.dateNotAllowed = true;
            }
        } else {
            participant.dateNotValid = true;
        }
    }

    function updateOrderTotal(services) {

        var servicesTotal = 0;

        for (var i = 0; i < services.length; i++) {

            var item = services[i],
                service = item.service;

            if (service) {
                servicesTotal += parseFloat(service.service_price, 10);
            }
        }
        return parseFloat(vm.orderTotal, 10) + parseFloat(servicesTotal, 10);
    }

    function removeFromArray(array, value) {
        var idx = array.indexOf(value);
        if (idx !== -1) {
            array.splice(idx, 1);
        }
        return array;
    }

    function initStartDays(services, servicePriceId) {

        // Find service from selected service price id.
        var filteredServices = services.filter(function (obj) {
            return obj.service_price_id === servicePriceId;
        });

        // Find default selected
        var defaultSelected = filteredServices.filter(function (obj) {
            return obj.selected == 1;
        })[0];

        if (defaultSelected) {
            setStartDays(services, defaultSelected);
        }
    }

    function setStartDays(services, service) {
        var arrStartDays = utilsService.range(service.startday_minimum, service.startday_maximum);

        services[0].startdays = arrStartDays;

        var currentStartDay = !utilsService.isUndefinedOrNull(services[0].selectedStartDay) ? parseInt(services[0].selectedStartDay) : null;

        //  Keep existing selected startday if in days range array
        if (currentStartDay !== null && arrStartDays.indexOf(currentStartDay) !== -1 &&
            currentStartDay >= service.startday_minimum && currentStartDay <= service.startday_maximum)
        {
            services[0].selectedStartDay = currentStartDay;
        }
        else
        {
            services[0].selectedStartDay = service.startday !== null ? service.startday : service.startday_default;
        }
    }

    function printTicket() {
        bookingResource.getTicket(vm.order.order_no, vm.order.pin_code)
            .then(function (response) {
                window.open(response.data.link, "_blank");
            }, function (err) {
                
            });
    }

    function sendTicket() {
        vm.ticketMailState.progress = true;
        vm.ticketMailState.success = false;
        vm.ticketMailState.error = false;
        bookingResource.sendTicket(vm.order.order_no, vm.order.pin_code)
            .then(function (response) {
                vm.ticketMailState.progress = false;
                vm.ticketMailState.success = true;
                vm.ticketMailState.error = false;

            }, function (err) {
                vm.ticketMailState.progress = false;
                vm.ticketMailState.success = false;
                vm.ticketMailState.error = true;
            });
    }

    function addDays(date, days) {

        days = parseInt(days) || 0;

        var d = new Date(date);
        var newdate = d.setDate(d.getDate() + days);

        var s = $filter("date")(newdate, "EEEE d. MMMM yyyy");
        return capitalize(s);
    }

    function capitalize(s) {
        return utilsService.capitalize(s);
    }

    function changePickupLocation(order, participant) {

        var count = 0;

        angular.forEach(order.participants, function (item, index) {

            var currentPickupLocation = item.departure_location_id || item.return_location_id || null;

            if (currentPickupLocation !== item.pickup_location) {
                count++;
            }
        });

        if (count > 0) {
            vm.pickupLocationChanged = true;
        }
        else {
            vm.pickupLocationChanged = false;
        }
    }

    function resolve(order) {

        var transactionStatus = utilsService.getParameterByName('paymentstatus');

        if (transactionStatus) {
            vm.transaction = {
                status: transactionStatus
            };
        }

        // Only create request for transport code 1? 
        bookingResource.getPickupLocations(order.order_no, order.pin_code, order.participants[0].participant_id).then(function (response) {
            if (response.status !== 200) {
                console.warn("Missing data for locations", response.status, response.statusText);
            } else {
                vm.pickupLocations = response.data;
            }
        });

        var adults = 0;
        var children = 0;

        angular.forEach(order.participants, function (item, index) {

            var orderPrefix = order.order_no.substr(0, 2);

            // Array of service group ids to exclude - note Skirejser use different service group ids
            var excludedServices = orderPrefix === "SS" ? [43] : [5, 11];

            // Update services by excluding discount services
            item.services = $filter("filter")(item.services, function (service) {
                
                var serviceGroupId = parseInt(service.service_group_id);

                // Return service if not in array of excluded services
                return excludedServices.indexOf(serviceGroupId) === -1;
            });

            item.adult = isAdult(item.age);

            if (item.age >= 18) {
                adults++;
            }
            else {
                children++;
            }

            item.pickup_location = setPickupLocation(item);
        });

        order.check_in_date = order.participants[0].check_in_date;
        order.check_out_date = order.participants[0].check_out_date;
        order.room_description = order.participants[0].room_description;

        order.adults = adults;
        order.children = children;

        order.paymentmethod = "creditcard";

        return order;
    }

    function setPickupLocation(participant) {
        var pickupLocationId = participant.departure_location_id || participant.return_location_id || null;

        return pickupLocationId;
    }

    function calculateServiceGroupTotalPrice(serviceGroupId, participants) {

        var total = 0;

        angular.forEach(participants, function (participant) {
            var services = $filter("where")(participant.services, { service_group_id: serviceGroupId, selected: 1 });
            angular.forEach(services, function (service) {
                var servicePrice = service.service_price;
                if (service.included == 1) {
                    servicePrice = service.service_order_price;
                }
                total += parseFloat(servicePrice);
            });
        });

        return total;
    }

    function filterParticipantService(potentialServices, service) {
        var participantService = $filter("where")(potentialServices, { service_group_id: service.service_group_id })[0];
        return participantService;
    }

    function watchOrderChange(order) {
        $timeout(function () {
            $rootScope.$broadcast('orderEvent', order);
        }, 0);
    }

    function getServiceInfo() {
        bookingResource.getServiceInfo(vm.activeServiceGroupId).then(function (response) {
            var serviceInfo = response.data;
            vm.serviceinfo.usp = serviceInfo.uspitems;
            vm.serviceinfo.description = $sce.trustAsHtml(serviceInfo.description); 
        })
        .catch(function () {
                
        });
    }

    function isAdult(age) {
        return age >= 18;
    }

    function isDate(value) {
        switch (typeof value) {
            case 'number':
                return true;
            case 'string':
                return !isNaN(Date.parse(value));
            case 'object':
                if (value instanceof Date) {
                    return !isNaN(value.getTime());
                }
            default:
                return false;
        }
    }

    $scope.$on('destinationInfoEvent', function (event, order) {
        vm.order = order;
    });
}]);