var app = angular.module("travelsearch.core");

app.controller('LastMinuteController', ["$scope", "$rootScope", "$filter", "$timeout", "$cookies", "travelSearchServices", "utilsService", function ($scope, $rootScope, $filter, $timeout, $cookies, travelSearchServices, utilsService) {

    var vm = this;
    vm.loading = true;
    vm.progress = false;

    // Update loading progress
    $timeout(function () {
        vm.progress = true;
    }, 15000);

    vm.getImageUrl = getImageUrl;

    $scope.error = false;
    $scope.hotellist = {};
    $scope.lastminutelist = {};

    $scope.travelFilters = {};

    var favCookieValue = $cookies.get("favorit");
    if (!angular.isUndefined(favCookieValue)) {
        $scope.hotellist.favorites = favCookieValue;
    }

    travelSearchServices.getLastMinuteHotels().then(function (response) {

        if (!angular.isUndefined(response.data) && response.data.length > 0) {
            $scope.lastminutelist.noresults = false;
            $scope.lastminutelist.travels = response.data;

            // Boards
            var boards = $filter("unique")($scope.lastminutelist.travels, 'board.value'); // unique filter;
            boards = boards.map(function (item) {

                var board = item.board;

                var bdt = getBoardDetails(board.value);
                var key = bdt[0];
                if (key === "") {
                    return null;
                }

                return {
                    value: board.value,
                    name: board.label,
                    key: key,
                    sort: bdt[1]
                };

            });

            boards = boards.filter(Boolean); // exclude empty values ("", null, NaN, undefined)

            if (boards) {
                // sort items
                boards = boards.sort(function (a, b) {
                    return a.sort > b.sort ? 1 : -1;
                });
            }

            // Dates
            var dates = $filter("unique")($scope.lastminutelist.travels, 'departuredate'); // unique filter;
            dates = $filter("orderBy")(dates, 'departuredate');
            dates = dates.map(function (item) {
                return {
                    value: item.departuredate,
                    weekno: item.weekno,
                    name: $filter("date")(item.departuredate, $rootScope.longDateFormat),
                    key: $filter("date")(item.departuredate, $rootScope.longDateFormat)
                };
            });

            // Transports
            var transports = $filter("unique")($scope.lastminutelist.travels, 'transportinfo.translatedcode'); // unique filter;
            transports = $filter("orderBy")(transports, 'transportinfo.code'); // order by transport code (Travify transport code)
            transports = transports.map(function (item) {
                return {
                    code: item.transportinfo.translatedcode,
                    name: item.transportinfo.codename + (item.transportinfo.code == 2 ? ' ' + item.transportinfo.departureairportdeparture : '')
                };
            });

            // Destinations
            var destinations = $filter("unique")($scope.lastminutelist.travels, 'destinationcode'); // unique filter;
            destinations = destinations.map(function (item) {
                return {
                    code: item.destinationcode,
                    name: item.destinationname
                };
            });

            $scope.travelFilters.boards = boards;
            $scope.travelFilters.dates = dates;
            $scope.travelFilters.transports = transports;
            $scope.travelFilters.destinations = destinations;

            angular.forEach($scope.lastminutelist.travels, function (travel) {

                var queryParams = {
                    transport: travel.transportinfo.translatedcode,
                    days: travel.travellength,
                    date: travel.departuredate,
                    adults: travel.adults,
                    children: travel.children
                };

                travel.url = travel.url + buildLastMinuteQueryString(queryParams);
            });

            $scope.filters = $rootScope.getLastMinuteFiltersFromQueryString();

            var sorting = $rootScope.getFilterSorting($scope.filters.sorter);

            // Filter travels on init
            $scope.lastminutelist.filteredTravels = $filter("travelFilter")($scope.lastminutelist.travels, $scope.filters);
            $scope.lastminutelist.filteredTravels = $filter("orderBy")($scope.lastminutelist.filteredTravels, sorting);
        }
        else {
            $scope.lastminutelist.noresults = true;
        }

        $timeout(function () {
            vm.loading = false;
            vm.progress = false;
        }, 500);
    },
    function (data) {
        $scope.error = true;

        $timeout(function () {
            vm.loading = false;
            vm.progress = false;
        }, 500);
    });

    function buildLastMinuteQueryString(params) {
        var queryString = "";

        if (!angular.isUndefined(params)) {
            queryString = "?";

            queryString = queryString + "saeson=winter";

            if (params.transport) {
                queryString = queryString + "&transport=" + params.transport;
            }

            if (params.days) {
                queryString = queryString + "&dage=" + params.days;
            }

            if (params.date) {
                queryString = queryString + "&dato=" + $filter('date')(params.date, $rootScope.longDateFormat);
            }

            if (params.adults) {
                queryString = queryString + "&voksne=" + params.adults;
            }

            if (params.children) {
                queryString = queryString + "&born=" + params.children;
            }
        }

        return queryString;
    }

    function getBoardDetails(value) {
        var key = "";
        var sortOrder = 0;

        switch (value) {
            case "No board":
                key = "none";
                sortOrder = 0;
                break;
            case "1/4 board":
                key = "quarter";
                sortOrder = 1;
                break;
            case "1/2 board":
                key = "half";
                sortOrder = 2;
                break;
            case "1/1 board":
                key = "full";
                sortOrder = 3;
                break;
            case "Can be purchased":
                key = "addon";
                sortOrder = 4;
                break;
            case "All inclusive":
                key = "allinclusive";
                sortOrder = 5;
                break;
        }

        return [key, sortOrder];
    }

    function getImageUrl(imageUrl, width, height, format) {
        var photo = imageUrl;
        return photo.substring(0, photo.indexOf("?")) + "?format=" + format + "&mode=crop&width=" + width + "&height=" + height + "&quality=60";
    }

}]);