angular.module("travelsearch.filters").filter('travelFilter', ["$rootScope", function ($rootScope) {
    return function (travels, filterval) {

        //if (!filterval || filterval.length === 0) return travels;

        var filters = filterval; //angular.copy(filterval);

        var matches = travels || [];

        if (filters) {

            // Filter own hotels
            if (filters.ownhotels)
            {
                matches = matches.filter(function (item) {
                    if (item.ownhotel === true)
                        return true;

                    return false;
                });
            }

            // Filter marking
            if (filters.marking)
            {
                matches = matches.filter(function (item) {

                    var data = filters.marking;

                    // Map object properties to key/value pair
                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key] };
                    });

                    var selectedValues = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    }).map(function (opt) {
                        return opt.key;
                    });

                    if (selectedValues.length === 0)
                        return true;

                    var marking = item.hotelmarking;

                    var match = selectedValues.some(function (val) {
                        return marking && val === replaceHotelMarkingChars(marking);
                    });

                    return match;
                });
            }

            // Filter standards
            if (filters.standards)
            {
                var data = filters.standards;

                // Map object properties to key/value pair
                var fields = Object.keys(data).map(function (key) {
                    return { key: key, value: data[key] };
                });

                var selectedValues = fields.filter(function (field) {
                    if (field.value === false) {
                        return false; // skip if not selected (false)
                    } return true;
                }).map(function (opt) {
                    return parseInt(opt.key);
                });
                
                if (selectedValues.length > 0)
                {
                    // Filter matches
                    matches = matches.filter(function (item) {
                        return selectedValues.indexOf(item.rating) >= 0;
                    });
                }
            }

            // Filter boards
            if (filters.boards)
            {
                matches = matches.filter(function (item) {

                    var data = filters.boards;

                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key], data: getBoardValueFromKey(key) };
                    });

                    // Map object properties to key/value pair
                    fields = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    });

                    var selectedValues = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    }).map(function (opt) {
                        return opt.data;
                    });

                    if (selectedValues.length === 0)
                        return true;
                
                    var board = item.board;

                    var match = selectedValues.some(function (val) {
                        return board && val === board.value;
                    });

                    return match;
                });
            }

            // Filter distances
            if (filters.distances)
            {
                // Filter matches
                matches = matches.filter(function (item) {

                    var data = filters.distances;

                    // Map object properties to key/value pair
                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key] };
                    });

                    fields = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    });

                    var fieldsPass = fields.every(function (d) {

                        var distance = item.distances[d.key];

                        if (distance && distance.value <= 300)
                            return true;

                        return false;
                    });

                    return fieldsPass;
                });
            }

            // Filter facilities
            if (filters.facilities)
            {
                // Filter matches
                matches = matches.filter(function (item) {

                    var data = filters.facilities;

                    // Map object properties to key/value pair
                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key] };
                    });

                    fields = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    });

                    var fieldsPass = fields.every(function (f) {

                        var facility = item.facilities[f.key];

                        if (facility && facility.value === true)
                            return true;

                        return false;
                    });

                    return fieldsPass;
                });
            }

            // LastMinute specific filtering

            // Filter dates
            if (filters.dates) {

                // Filter matches
                matches = matches.filter(function (item) {

                    var data = filters.dates;

                    // Map object properties to key/value pair
                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key], data: $rootScope.parseDate(key, $rootScope.longDateFormat.toLowerCase()) };
                    });

                    var selectedValues = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    }).map(function (opt) {
                        return opt.data;
                    });

                    if (selectedValues.length === 0)
                        return true;

                    // Match any
                    var match = selectedValues.some(function (val) {
                        return item && val.getTime() === $rootScope.parseDate(item.departuredate).getTime();
                    });

                    return match;
                });
            }

            // Filter transports
            if (filters.transports) {

                // Filter matches
                matches = matches.filter(function (item) {

                    var data = filters.transports;

                    // Map object properties to key/value pair
                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key] };
                    });

                    var selectedValues = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    }).map(function (opt) {
                        return opt.key;
                    });

                    if (selectedValues.length === 0)
                        return true;

                    // Match any
                    var match = selectedValues.some(function (val) {
                        return item && val === item.transportinfo.translatedcode;
                    });

                    return match;
                });
            }

            // Filter destinations
            if (filters.destinations) {

                // Filter matches
                matches = matches.filter(function (item) {

                    var data = filters.destinations;

                    // Map object properties to key/value pair
                    var fields = Object.keys(data).map(function (key) {
                        return { key: key, value: data[key] };
                    });
                    
                    var selectedValues = fields.filter(function (field) {
                        if (field.value === false) {
                            return false; // skip if not selected (false)
                        } return true;
                    }).map(function (opt) {
                        return opt.key;
                    });

                    if (selectedValues.length === 0)
                        return true;

                    // Match any
                    var match = selectedValues.some(function (val) {
                        return item && val === item.destinationcode;
                    });

                    return match;

                    // Match all
                    //var fieldsPass = fields.every(function (f) {

                    //    if (f.key === item.destinationcode && f.value === true)
                    //        return true;

                    //    return false;
                    //});

                    //return fieldsPass;
                    
                });
            }

        }

        return matches;
    };

    function getBoardValueFromKey(key) {

        var value = "";

        switch (key) {
            case "none":
                value = "No board";
                break;
            case "quarter":
                value = "1/4 board";
                break;
            case "half":
                value = "1/2 board";
                break;
            case "full":
                value = "1/1 board";
                break;
            case "addon":
                value = "Can be purchased";
                break;
            case "allinclusive":
                value = "All inclusive";
                break;
            default:
                value = "";
                break;
        }

        return value;
    }

    function replaceHotelMarkingChars(value) {
        return value
            .replace("&", "")
            .replace("!", "")
            .replace(",", "")
            .replace("|", "")
            .replace(">", "")
            .replace("<", "")
            .replace(" ", "")
            .replace("'", "")
            .replace(".", "")
            .replace("%", "")
            .replace("/", "")
            .replace("\\", "")
            .replace(":", "")
            .replace("#", "")
            .replace("*", "")
            .replace("?", "")

            .replace("æ", "_ae1_")
            .replace("Æ", "_ae2_")
            .replace("ø", "_oe1_")
            .replace("Ø", "_oe2_")
            .replace("å", "_aa1_")
            .replace("Å", "_aa2_")

            .replace("ä", "_ae3_")
            .replace("Ä", "_ae4_")

            .replace("ö", "_oe3_")
            .replace("Ö", "_oe4_")

            .replace("ü", "_ue1_")
            .replace("Ü", "_ue2_")

            .replace("ß", "_ss_")
            .toLowerCase();
    }

}]);