var app = angular.module("travelsearch.core");

app.controller('LastMinuteFilterController', ["$rootScope", "$scope", "$cookies", "$filter", "utilsService", function ($rootScope, $scope, $cookies, $filter, utilsService) {

    var now = new Date();
    $scope.state = {};
    $scope.filters = $rootScope.getLastMinuteFiltersFromQueryString();

    var filterData = $scope.filters;
    var filterFields = Object.keys(filterData).map(function (key) {
        return {
            key: key,
            value: filterData[key]
        };
    });

    $scope.state.count = 0;

    angular.forEach(filterFields, function (obj, index) {

        // skip count
        if (obj.key === "sorter")
            return;

        if (angular.isObject(obj.value)) {
            $scope.state.count += Object.keys(obj.value).length;
        }
        else if (obj.value === true) {
            $scope.state.count++;
        }
    });

    $scope.state.statustext = angular.element("#toggleFiltering").data("closed");
    $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("closed");

    if ($scope.state.count > 1) {
        $scope.state.statustext = angular.element("#toggleFiltering").data("active");
        $scope.state.statustextInline = angular.element("#toggleFiltering").data("active");
    }

    $scope.openMobileFilters = function () {
        $("#filters").toggleClass("open");
    };

    $scope.openFilters = function () {
        $("#filters").slideToggle();
    };

    $scope.setFilterText = function () {
        if ($scope.state.statustext == angular.element("#toggleFiltering").data("open")) {
            if ($scope.state.count > 1) {
                $scope.state.statustext = angular.element("#toggleFiltering").data("active");
            } else {
                $scope.state.statustext = angular.element("#toggleFiltering").data("closed");
            }
        } else {
            $scope.state.statustext = angular.element("#toggleFiltering").data("open");
        }
    };

    $scope.setFilterTextInline = function () {
        if ($scope.state.statustextInline == angular.element("#toggleFiltering-inline").data("open")) {
            if ($scope.state.count > 1) {
                $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("active");
            } else {
                $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("closed");
            }
        } else {
            $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("open");
        }
    };

    $scope.resetFilters = function () {
        $scope.filters = {};
        $scope.filters.sorter = "4";
        $scope.updateFilters($scope.filters, "reset");
    };

    $scope.updateFilters = function (filterObj, key) {
        $scope.filters = filterObj;

        angular.forEach(filterObj, function (obj, key) {
            if (angular.isObject(obj)) {
                angular.forEach(obj, function (value, index) {
                    if (!value) {
                        delete $scope.filters[key][index];
                    }
                });
            }
            if (!obj) {
                delete $scope.filters[key];
            }
        });

        var filterData = $scope.filters;
        var filterFields = Object.keys(filterData).map(function (key) {
            return {
                key: key,
                value: filterData[key]
            };
        });

        $scope.state.count = 0;

        angular.forEach(filterFields, function (obj, index) {

            // skip count
            if (obj.key === "sorter")
                return;

            if (angular.isObject(obj.value)) {
                $scope.state.count += Object.keys(obj.value).length;
            }
            else if (obj.value === true) {
                $scope.state.count++;
            }
        });

        var sorting = $rootScope.getFilterSorting($scope.filters.sorter);

        // Using custom filter from controller to filter travels
        $scope.lastminutelist.filteredTravels = $filter("travelFilter")($scope.lastminutelist.travels, $scope.filters);
        $scope.lastminutelist.filteredTravels = $filter("orderBy")($scope.lastminutelist.filteredTravels, sorting);

        // Toggle disable state of filters after filtering travels
        //if ($scope.state.count === 0)
        //{
        //    checkFilterGroups($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //}
        //else
        //{
        //    switch (key)
        //    {
        //        case "board":
        //            checkFilterGroupDates($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupTransports($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDestinations($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            break;
        //        case "date":
        //            checkFilterGroupBoards($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDestinations($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupTransports($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            break;
        //        case "destination":
        //            checkFilterGroupBoards($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDates($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupTransports($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            break;
        //        case "transport":
        //            checkFilterGroupBoards($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDates($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDestinations($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            break;
        //        case "reset":
        //            checkFilterGroupBoards($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDates($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupTransports($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            checkFilterGroupDestinations($scope.lastminutelist.filteredTravels, $scope.travelFilters);
        //            break;
        //    }
        //}

    };

    function checkFilterGroupBoards(travels, filters) {
        if (filters.boards) {
            var boards = $filter("unique")(travels, 'board.value');
            boards = $filter("map")(boards, 'board.value');
            boards = boards.filter(Boolean); // exclude empty strings

            filters.boards.map(function (item) {
                item.disabled = boards.indexOf(item.value) === -1;
                return item;
            });
        }
    }

    function checkFilterGroups(travels, filters) {
        checkFilterGroupBoards(travels, filters);
        checkFilterGroupDates(travels, filters);
        checkFilterGroupTransports(travels, filters);
        checkFilterGroupDestinations(travels, filters);
    }

    function checkFilterGroupDestinations(travels, filters) {

        if (filters.destinations) {
            var destCodes = $filter("unique")(travels, 'destinationcode');
            destCodes = $filter("map")(destCodes, 'destinationcode');

            filters.destinations.map(function (item) {
                item.disabled = destCodes.indexOf(item.code) === -1;
                return item;
            });
        }

        return filters;
    }

    function checkFilterGroupTransports(travels, filters) {

        if (filters.transports) {
            var transportCodes = $filter("unique")(travels, 'transportinfo.translatedcode');
            transportCodes = $filter("map")(transportCodes, 'transportinfo.translatedcode');

            filters.transports.map(function (item) {
                item.disabled = transportCodes.indexOf(item.code) === -1;
                return item;
            });
        }

        return filters;
    }

    function checkFilterGroupDates(travels, filters) {

        if (filters.dates) {
            var dates = $filter("unique")(travels, 'departuredate');
            dates = $filter("map")(dates, 'departuredate');
            dates = dates.map(function (date) {
                var d = $filter("date")(date, 'yyyy-MM-dd');
                return d;
            });

            filters.dates.map(function (item) {
                var date = $filter("date")(item.value, "yyyy-MM-dd");
                item.disabled = dates.indexOf(date) === -1;
                return item;
            });
        }

        return filters;
    }

}]);