$(function () {
    $('body').on('hidden.bs.modal', '.modal', function () {
        $(this).removeData('bs.modal');
    });


    $(document).on("click", ".view-service-details", function () {
        var serviceId = $(this).data('id');

        $('#serviceDetailsDialog .modal-body').load("/Umbraco/Surface/Service/GetService?id=" + serviceId, function (response, status, xhr) {
            //$("#serviceDetailsDialog").find(".modal-body").html(response.data);

            $("#serviceDetailsDialog").modal("show");
        });
    });
});