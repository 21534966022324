angular.module('stg.travify.services')
    .factory('settingsService', ['$q', '$cookies', 'authResource', function ($q, $cookies, authResource) {
        var authToken = null;
        return {
            // New function which returns a promise
            getAuthenticationToken: function (forceRefresh) {
                forceRefresh = forceRefresh || false;

                var deferred = $q.defer();

                if (!forceRefresh) {
                    var authCookie = $cookies.get("travifyToken");

                    if (authCookie) {
                        authToken = authCookie;

                        deferred.resolve(authToken);
                        return deferred.promise;
                    }
                }

                authResource.getAuthToken()
                    .then(function (response) {
                        authToken = response.data;
                        deferred.resolve(authToken);
                            
                    }, function() {
                        deferred.reject();
                    });

                return deferred.promise;
            }
        };
    }]);