var app = angular.module("travelsearch.core");

app.requires.push('angular.filter');
app.requires.push('ngPromiseExtras');

app.controller('MyPage.Controller', ['$scope', '$rootScope', '$timeout', '$filter', '$q', '$sce', 'bookingResource', 'utilsService', 'cookieService', 'errorHandler', function ($scope, $rootScope, $timeout, $filter, $q, $sce, bookingResource, utilsService, cookieService, errorHandler) {

    var vm = this;

    vm.order = null;
    vm.loading = true;

    vm.error = false;
    vm.errorType = null;

    vm.showLogin = true;
    vm.submitLogin = submitLogin;

    init();

    function init() {

        var orderId = null;
        var pincode = null;
        var bookingNo = null;

        if (utilsService.getParameterByName('orderid')) {
            orderId = utilsService.getParameterByName('orderid');
        }
        if (utilsService.getParameterByName('order_id')) {
            orderId = utilsService.getParameterByName('order_id');
        }

        if (utilsService.getParameterByName('pincode')) {
            pincode = utilsService.getParameterByName('pincode');
        }
        if (utilsService.getParameterByName('pin_code')) {
            pincode = utilsService.getParameterByName('pin_code');
        }

        if (utilsService.getParameterByName('bookingno')) {
            bookingNo = utilsService.getParameterByName('bookingno');
        }
        if (utilsService.getParameterByName('booking_no')) {
            bookingNo = utilsService.getParameterByName('booking_no');
        }

        if ((orderId || bookingNo) && pincode) {
            var bookId = orderId;
            if (bookingNo) {
                bookId = bookingNo;
            }

            getOrder(bookId, pincode);

        } else {
            var orderInfo = cookieService.getCookieData("mybooking");

            if (!angular.isUndefined(orderInfo) && orderInfo !== null) {
                try {
                    if (orderInfo.id && orderInfo.pincode) {
                        getOrder(orderInfo.id, orderInfo.pincode);
                    }
                }
                catch (err) {
                    vm.error = true;
                    vm.loading = false;
                }
            } else {
                vm.loading = false;
                vm.showLogin = true;
            }
        }
    }

    function getOrder(bookId, pincode) {
        
        bookingResource.getOrder(bookId, pincode).then(function (response) {

            var data = response.data;

            if (response.status !== 200) {
                console.warn("Missing data for booking", response.status, response.statusText);
                vm.error = true;
                vm.errorType = response.status;
                vm.errorResponse = response.statusText;

                vm.showLogin = true;
            } else {
                if (data.order_status.toLowerCase() !== "booking") {
                    vm.error = true;
                    vm.errorResponse = response.statusText;
                    vm.errorType = "InvalidBooking";

                    vm.showLogin = true;
                } else {
                    vm.order = resolve(data);

                    var obj = {
                        id: bookId,
                        pincode: pincode,
                        firstname: vm.order.customer.first_name
                    };

                    cookieService.setCookieData("mybooking", obj);

                    vm.showLogin = false;
                }               
            }
            vm.loading = false;
        })
        .catch(function (response) {
            console.error("Error fetching booking", response.status, response.statusText);

            errorHandler.handleError(response);

            vm.error = true;
            vm.errorType = response.status;
            vm.loading = false;
        });
    }

    function submitLogin(form) {

        var username = form.username.$modelValue;
        var password = form.password.$modelValue;

        if (form.$invalid) {
            return;
        }

        var obj = {
            id: username,
            pincode: password
        };

        getOrder(obj.id, obj.pincode);
    }
    
    function resolve(order) {
        var adults = 0;
        var children = 0;

        angular.forEach(order.participants, function (item, index) {
  
            if (item.age >= 18) {
                adults++;
            }
            else {
                children++;
            }
        });

        order.adults = adults;
        order.children = children;

        order = getHotel(order);
        order = getArticles(order);

        return order;
    }

    function getHotel(order) {
        bookingResource.getHotel(order.accomodation_code).then(function (response) {
            var hotel = response.data[0];

            order.hotel_name = hotel.name;
            order.country = hotel.destinationcountry;
            order.destinationname = hotel.destinationname;
            order.destinationcode = hotel.destinationcode;
            
            vm.loading = false;
            
        })
        .catch(function (hotel) {
            vm.error = true;
            vm.loading = false;
        });

        return order;
    }

    function getArticles(order) {
        bookingResource.getArticles(order.destinationcode, order.accomodation_code, order.departure_date).then(function (response) {
            order.articles = response.data;
            vm.loading = false;

        })
        .catch(function (hotel) {
            vm.error = true;
            vm.loading = false;
        });

        return order;
    }
}]);