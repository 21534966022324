'use strict';

var travelSearchApp = angular.module("travelsearch");

travelSearchApp.requires.push('nya.bootstrap.select');

travelSearchApp.config(['nyaBsConfigProvider', function (nyaBsConfigProvider) {
    nyaBsConfigProvider.setLocalizedText('da-dk', {
        defaultNoneSelection: 'Vælg...',
        noSearchResult: 'Intet resultat',
        numberItemSelected: '%d element(er) valgt',
        selectAll: 'Vælg alle',
        deselectAll: 'Fravælg alle'
    });
    nyaBsConfigProvider.useLocale('da-dk');
}]);