//angular.module('stg.travify.core', []);
angular.module('stg.travify.directives', []);
angular.module('stg.travify.resources', []);
angular.module('stg.travify.services', []);

var travelSearchApp = angular.module("travelsearch", [
    'ngCookies',
    'ui.date',
    'tmh.dynamicLocale',
    'travelsearch.core',
    'travelsearch.services',
    'travelsearch.resources',
    'travelsearch.filters',
    'travelsearch.config',
    'stg.travify.directives',
    'stg.travify.resources',
    'stg.travify.services'
]);