angular.module('stg.travify.resources').factory('bookingResource', ['$q', '$http', 'settingsService', function ($q, $http, settingsService) {

    var host = window.location.hostname;
    var travifyApi = "https://api.travify.dk";

    var currentUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

    var clientIP = angular.element("body").data("ip");

    if (host.contains("test.") || host.contains("staging.") || host.contains(".local") || host.contains(".umbraco.io")) {
        travifyApi = "https://apitest.travify.dk";
    }

    var authorizationToken = null;

    return {
        checkPinCode: function (pincode) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'GET',
                    url: travifyApi + '/orders/' + id + "/check_pincode?auth_token=" + authorizationToken + "&pincode=" + pincode + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        getOrder: function (id, pincode, onlyselectedservices) {
            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            if (!onlyselectedservices) {
                onlyselectedservices = false;
            }

            return $q.all(promises).then(function (values) {

                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'GET',
                    url: travifyApi + '/orders/' + id + "?auth_token=" + authorizationToken + "&pincode=" + pincode + "&only_selected=" + onlyselectedservices + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        getOrderByUuid: function (uuid) {
            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {

                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'GET',
                    url: travifyApi + '/orders/' + uuid + "/participants/guest_payments?auth_token=" + authorizationToken + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        getPickupLocations: function (id, pincode, participantid) {
            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {

                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'GET',
                    url: travifyApi + '/orders/' + id + "/participants/" + participantid + "/locations?auth_token=" + authorizationToken + "&pincode=" + pincode + "&direction=departure&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };
                return $http(req);
            });
        },
        createOrder: function (periodId, roomString, transportId, ageString, hostName) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'POST',
                    url: travifyApi + "/orders?auth_token=" + authorizationToken + "&period_id=" + periodId + "&room_string=" + roomString + "&transport_id=" + transportId + "&age_string=" + ageString + "&origin_url=" + hostName + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);

            });
        },
        finalizeOrder: function (order) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                // Send order model in request.
                var data = order;

                var req = {
                    method: 'POST',
                    url: '/Umbraco/Surface/BookingFinalize/Finalize',
                    data: data,
                    dataType: 'json',
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);

            });
        },
        updateParticipant: function (orderId, participantId, name, gender, age, birthdate, pincode, pickupLocationId) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                if (angular.isUndefined(pickupLocationId) || pickupLocationId === null) {
                    pickupLocationId = 0;
                }

                var req = {
                    method: 'PATCH',
                    url: travifyApi +
                        '/orders/' + orderId +
                        "/participants/" + participantId +
                        "?auth_token=" + authorizationToken +
                        "&pincode=" + pincode +
                        "&full_name=" + encodeURIComponent(name) +
                        "&gender=" + gender +
                        "&age=" + age +
                        (birthdate ? "&birthdate=" + birthdate : "") + 
                        (pickupLocationId > 0 ? "&departure_location_id=" + pickupLocationId + "&return_location_id=" + pickupLocationId : "") +
                        "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        getCountries: function () {

            var req = {
                method: 'GET',
                url: '/Import/Countries/countries.json',
                cache: true
            };

            return $http(req);
        },
        updateCustomer: function (orderId, emailAddress, title, firstName, lastName, address, postalCode, city, country, phone, phoneCode, pincode) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                var url = travifyApi + '/orders/' + orderId + "/customers/patch?auth_token=" + authorizationToken + "&pincode=" + pincode + 
                    (title ? "&title_abbreviation=" + title : "") + 
                    (firstName ? "&first_name=" + encodeURIComponent(firstName) : "") +
                    (lastName ? "&last_name=" + encodeURIComponent(lastName) : "") +
                    (address ? "&address=" + encodeURIComponent(address) : "") +
                    (postalCode ? "&postal_code=" + encodeURIComponent(postalCode) : "") +
                    (city ? "&city=" + encodeURIComponent(city) : "") +
                    (country ? "&country=" + country : "") +
                    (phone ? "&phone=" + encodeURIComponent(phone) : "") +
                    (phoneCode ? "&phone_country_code=" + phoneCode : "") +
                    (emailAddress ? "&email_address=" + encodeURIComponent(emailAddress) : "") + 
                    "&client_ip_address=" + clientIP;

                var req = {
                    method: 'PATCH',
                    url: url,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        attachService: function (orderId, participantId, serviceGroupId, servicePriceId, pincode, startDay) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                if (angular.isUndefined(startDay) || startDay === null) {
                    startDay = 1;
                }

                var req = {
                    method: 'PATCH',
                    url: travifyApi + "/orders/" + orderId + "/participants/" + participantId + "/services/attach?auth_token=" + authorizationToken + "&pincode=" + pincode + "&service_group_id=" + serviceGroupId + "&service_price_id=" + servicePriceId + "&start_day=" + startDay + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        detachService: function (orderId, participantId, servicePriceId, pincode) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'PATCH',
                    url: travifyApi + "/orders/" + orderId + "/participants/" + participantId + "/services/detach?auth_token=" + authorizationToken + "&pincode=" + pincode + "&service_price_id=" + servicePriceId + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        getPayments: function (id, pincode) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {

                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'GET',
                    url: travifyApi + '/orders/' + id + "/payments?auth_token=" + authorizationToken + "&pincode=" + pincode + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        requestPayment: function (order, amount, paymentIdentification, gateway) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {

                authorizationToken = values.authorizationToken;

                var url = "/Umbraco/Api/QuickPayPayment/RequestPayment";
                if (gateway == "altapay") {
                    url = "/Umbraco/Api/AltaPayPayment/RequestPayment";
                }

                var customer = {
                    firstName: order.customer.first_name,
                    lastName: order.customer.last_name,
                    address: order.customer.address,
                    postalCode: order.customer.postal_code,
                    city: order.customer.city,
                    countryCode: order.customer.country_code,
                    phone: order.customer.phone_number,
                    email: order.customer.email_address
                };

                var data =
                {
                    orderId: order.id,
                    hotelName: order.hotel_name,
                    hotelCode: order.accomodation_code,
                    destinationCode: order.destination_code,
                    price: order.total_amount,
                    customer: customer,
                    amount: amount,
                    paymentIdentification: paymentIdentification, //order.order_payment_identification
                    pinCode: order.pin_code,
                    authorization: authorizationToken,
                    uuid: order.uuid,
                    returnurl: currentUrl,
                    paymentmethod: order.paymentmethod
                };

                var req = {
                    method: 'POST',
                    url: url,
                    cache: false,
                    dataType: 'json',
                    data: data,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        subscribeNewsletter: function (email, firstName, lastName, phone) {

            var data =
            {
                email: email,
                firstName: firstName,
                lastName: lastName,
                phone: phone
            };

            var req = {
                method: 'POST',
                url: "/Umbraco/Api/HeyLoyalty/Subscribe",
                cache: false,
                dataType: 'json',
                data: data,
                headers: {
                    'Accept': 'application/json'
                }
            };

            return $http(req);
        },
        getTicket: function (orderId, pincode) {
            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                var req = {
                    method: 'GET',
                    url: travifyApi + "/orders/" + orderId + "/get_travel_document?auth_token=" + authorizationToken + "&pincode=" + pincode + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        sendTicket: function (orderId, pincode) {
            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };
            return $q.all(promises).then(function (values) {
                var req = {
                    method: 'POST',
                    url: travifyApi + "/orders/" + orderId + "/send_travel_document?auth_token=" + authorizationToken + "&pincode=" + pincode + "&client_ip_address=" + clientIP,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
        getHotel: function (hotelCode) {
            
            var req = {
                method: 'GET',
                url: "/Umbraco/Api/Hotels/GetHotels/" + hotelCode,
                cache: true,
                dataType: 'json',
                headers: {
                    'Accept': 'application/json'
                }
            };

            return $http(req);
        },
        getServiceInfo: function (serviceId) {
            var req = {
                method: 'GET',
                url: "/Umbraco/Api/Service/GetService/" + serviceId,
                cache: true,
                dataType: 'json',
                headers: {
                    'Accept': 'application/json'
                }
            };

            return $http(req);
        },
        getArticles: function (destinationId, hotelId, departureDate) {
            var req = {
                method: 'GET',
                url: "/Umbraco/Api/MyPageArticles/GetArticles",
                params: {
                    destinationid: destinationId,
                    hotelid: hotelId,
                    date: departureDate
                },
                cache: true,
                dataType: 'json',
                headers: {
                    'Accept': 'application/json'
                }
            };

            return $http(req);
        },
        addDiscountCode: function (orderId, pincode, discountCode) {

            var promises = {
                authorizationToken: settingsService.getAuthenticationToken()
            };

            return $q.all(promises).then(function (values) {
                authorizationToken = values.authorizationToken;

                var url = travifyApi + '/orders/' + orderId + "/apply_discount_code?auth_token=" + authorizationToken + "&pincode=" + pincode + "&discount_code=" + discountCode

                var req = {
                    method: 'POST',
                    url: url,
                    cache: false,
                    headers: {
                        'Accept': 'application/json'
                    }
                };

                return $http(req);
            });
        },
    };
}]);