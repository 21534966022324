angular.module('stg.travify.resources').factory('authResource', ['$http', function authResource($http) {

    return {
        getAuthToken: function () {
            return $http.get("/Umbraco/api/Auth/GetAuthTokenAsync?brandId=" + getBrand(),
                'Could not retrive authorization token');
        }
    };
}]);

function getBrand() {
    var brandId = "";
    var hostName = window.location.hostname.replace("www.", "").replace("test.", "").replace("staging.", "").replace("oerskov.", "").replace("umbraco.io", "").replace(".local", "");

    switch (hostName) {
        case "hojmark.dk":
            brandId = "6";
            break;
        case "lionalpin.se":
            brandId = "12";
            break;
        case "lionalpin.no":
            brandId = "9";
            break;
        case "alpeffecthotels.com":
            brandId = "13";
            break;
        case "alpeffecthotels.at":
            brandId = "13";
            break;
        case "skirejser.dk":
            brandId = "1";
            break;
        case "vinterly.dk":
            brandId = "11";
            break;
        case "onlineski.dk":
            brandId = "10";
            break;
        default:
            brandId = "";
    }

    return brandId;
}