$(function () {

    $(".image-gallery").lightSlider({
        item: 6,
        loop: false,
        enableTouch: true,
        enableDrag: true,
        freeMove: true,
        swipeThreshold: 40,
        slideMove: 2,
        slideMargin: 6,
        addClass: 'image-gallery-slider',
        prevHtml: '<i class="fas fa-chevron-left"></i>',
        nextHtml: '<i class="fas fa-chevron-right"></i>',
        easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
        speed: 600,
        controls: true,
        pager: false,
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    item: 5,
                    slideMove: 1
                }
            },
            {
                breakpoint: 800,
                settings: {
                    item: 4,
                    slideMove: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    item: 3,
                    slideMove: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    item: 2,
                    slideMove: 1
                }
            }
        ]
    });

});