var app = angular.module("travelsearch.core");

app.requires.push('angular.filter');
app.requires.push('ngPromiseExtras');

app.controller('GuestPayments.Controller', ['$scope', '$rootScope', '$timeout', '$filter', '$q', 'bookingResource', 'utilsService', 'cookieService', 'errorHandler', function ($scope, $rootScope, $timeout, $filter, $q, bookingResource, utilsService, cookieService, errorHandler) {

    var vm = this;

    vm.orderTotal = 0;

    vm.order = null;
    vm.loading = true;

    vm.error = false;
    vm.errorType = null;

    vm.transaction = null;

    vm.submitPayment = submitPayment;
    vm.submitCustomPayment = submitCustomPayment;

    vm.capitalize = capitalize;

    var currentUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;

    init();

    function init() {

        var orderUuid = null;

        if (utilsService.getParameterByName('uuid')) {
            orderUuid = utilsService.getParameterByName('uuid');
        } else {
            orderUuid = cookieService.getCookieData("orderUuid")
        }
        
        if (orderUuid) {
            try {
                getOrder(orderUuid);
            }
            catch (err) {
                vm.error = true;
                vm.loading = false;
            }
        } else {
            vm.error = true;
            vm.loading = false;
        }
    }

    function getOrder(orderUuid) {

        bookingResource.getOrderByUuid(orderUuid).then(function (response) {

            var data = response.data;

            if (response.status !== 200) {
                console.warn("Missing data for booking", response.status, response.statusText);
                vm.error = true;
                vm.errorType = response.status;
                vm.errorResponse = response.statusText;

                vm.showLogin = true;
            } else {
                if (data.order_status.toLowerCase() !== "booking") {
                    vm.error = true;
                    vm.errorResponse = response.statusText;
                    vm.errorType = "InvalidBooking";

                    vm.showLogin = true;
                } else {
                    // Extend order with additional properties

                    vm.order = resolve(data);
                    vm.order.uuid = orderUuid;
                    vm.orderTotal = vm.order.total_amount;
                    
                    cookieService.setCookieData("orderUuid", orderUuid);

                    vm.showLogin = false;

                    watchOrderChange(vm.order);
                }               
            }
            vm.loading = false;
        })
        .catch(function (response) {
            console.error("Error fetching booking", response.status, response.statusText);

            errorHandler.handleError(response);

            vm.error = true;
            vm.errorType = response.status;
            vm.loading = false;
        });
    }

    function submitPayment(form, amount, paymentIdentification) {

        amount = amount || 0;

        if (form.$invalid || amount <= 0) {
            return;
        }

        var gateway = angular.element("#guest-payments").data("gateway");

        bookingResource.requestPayment(vm.order, amount, paymentIdentification, gateway).then(function (response) {
            var data = response.data;

            if (gateway == "quickpay") {
                window.location.href = data;
            } else {
                window.location.href = data;
            }
        })
        .catch(function (data) {

        });
    }

    function submitCustomPayment(form, paymentIdentification) {

        if (form.$invalid) {
            return;
        }

        var amount = !angular.isUndefined(form.paymentAmount) && form.paymentAmount !== null ? form.paymentAmount.$modelValue : null;

        if (!amount) {
            return;
        }

        var gateway = angular.element("#guest-payments").data("gateway");

        bookingResource.requestPayment(vm.order, amount, paymentIdentification, gateway)
            .then(function (response) {
                var data = response.data;
                window.location.href = data;
            })
            .catch(function (data) {

            });
    }
    
    function capitalize(s) {
        return utilsService.capitalize(s);
    }

    function resolve(order) {

        var transactionStatus = utilsService.getParameterByName('paymentstatus');

        if (transactionStatus) {
            vm.transaction = {
                status: transactionStatus
            };
        }

        var adults = 0;
        var children = 0;

        angular.forEach(order.participants, function (item, index) {
            item.deposit_amount_remaining = Math.max(0, item.total_deposit_for_participant - item.total_paid_for_participant)
            item.booking_amount_remaining = Math.max(0, item.total_price_for_participant - item.total_paid_for_participant)
            
            if (item.age >= 18) {
                adults++;
            }
            else {
                children++;
            }
        });

        order.check_in_date = order.participants[0].check_in_date;
        order.check_out_date = order.participants[0].check_out_date;
        order.room_description = order.participants[0].room_description;

        order.adults = adults;
        order.children = children;

        return order;
    }

    function watchOrderChange(order) {
        $timeout(function () {
            $rootScope.$broadcast('orderEvent', order);
        }, 0);
    }

    $scope.$on('destinationInfoEvent', function (event, order) {
        vm.order = order;
    });
}]);