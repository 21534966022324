angular.module("travelsearch.services").factory("hotelService", ["$http", function ($http) {
    var fac = {};

    fac.getHotels = function (hotelCodes, limit) {

        var req = {
            method: 'GET',
            url: '/Umbraco/Api/Hotels/GetHotels',
            params: { id: hotelCodes, limit: limit },
            cache: true
        };

        return $http(req);
    };

    return fac;
}]);