angular.module("travelsearch.core").directive('lightslider', ['$timeout', function ($timeout) {
    return {
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {
            if (!ngModel) return;

            if (scope.$last) {  

                $timeout(function () {
                    // ng-repeat is completed
                    element.parent().lightSlider({
                        item: 3,
                        autoWidth: false,
                        slideMove: 3,
                        slideMargin: 30,
                        enableTouch: true,
                        enableDrag: false,
                        freeMove: true,
                        swipeThreshold: 40,
                        loop: false,
                        auto: false,
                        pauseOnHover: false,
                        prevHtml: '<i class="fas fa-chevron-left"></i>',
                        nextHtml: '<i class="fas fa-chevron-right"></i>',
                        responsive: [
                            {
                                breakpoint: 991,
                                settings: {
                                    item: 2,
                                    slideMove: 1,
                                    slideMargin: 30
                                }
                            },
                            {
                                breakpoint: 600,
                                settings: {
                                    item: 1,
                                    slideMove: 1
                                }
                            }
                        ],
                        onSliderLoad: function (el) {

                            // workaround to ensure container set height matching largest height of children
                            // https://github.com/sachinchoolur/lightslider/issues/271

                            var maxHeight = 0,
                                container = $(el),
                                children = container.children();

                            children.each(function () {
                                var childHeight = $(this).height();
                                if (childHeight > maxHeight) {
                                    maxHeight = childHeight;
                                }
                            });
                            container.height(maxHeight);
                        }
                    });

                }, 0);
            }
        }
    };
}]);