angular.module('stg.travify.services').factory('utilsService', function () {

    var service = {
        isUndefinedOrNull: function (val) {
            return angular.isUndefined(val) || val === null;
        },
        getParameterByName: function (name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        },
        convertToCSV: function (objArray) {
            var str = "";
            for (var i in objArray) {
                str = str + objArray[i];
                if (i < Object.keys(objArray).length - 1) {
                    str = str + ",";
                }
            }
            return str;
        },
        range: function(min, max, step) {
            step = step || 1;
            var input = [];
            for (var i = min; i <= max; i += step) {
                input.push(i);
            }
            return input;
        },
        capitalize: function(s) {
            if (typeof s !== 'string') return '';
            return s.charAt(0).toUpperCase() + s.slice(1);
        }
    };

    return service;
});