var app = angular.module("travelsearch.core");
app.controller('Booking.Overview.Controller', ['$scope', '$rootScope', '$timeout', '$cookies', '$window', 'bookingResource', function ($scope, $rootScope, $timeout, $cookies, $window, bookingResource) {

    var vm = this;
    vm.loading = true;
    vm.error = true;

    vm.getImageUrl = getImageUrl;

    $scope.$on('orderEvent', function (event, obj) {
        vm.order = resolve(obj);
    });

    function resolve(order) {

        order.departure_duration = getDateDiff(order.departure_time_for_departure, order.arrival_time_for_departure);
        order.return_duration = getDateDiff(order.departure_time_for_return, order.arrival_time_for_return);

        order.check_in_date = order.participants[0].check_in_date;
        order.check_out_date = order.participants[0].check_out_date;

        bookingResource.getHotel(order.accomodation_code).then(function (response) {
            var hotel = response.data[0];

            order.hotel_name = hotel.name;
            order.hotel_address = hotel.address;
            order.hotel_zip = hotel.zip;
            order.hotel_city = hotel.city;

            order.country = hotel.destinationcountry;
            order.destination = hotel.destinationname;
            order.image_url = hotel.photos[0].url;
            order.priceincludes = hotel.priceincludes;

            watchDestinationInfoChange(order);

            vm.loading = false;
        })
        .catch(function (hotel) {
            vm.error = true;
            vm.loading = false;
        });

        return order;
    }

    function getImageUrl(imageUrl, width, height, format) {
        var photo = imageUrl;
        return photo.substring(0, photo.indexOf("?")) + "?format=" + format + "&mode=crop&width=" + width + "&height=" + height + "&quality=60";
    }

    function watchDestinationInfoChange(order) {
        $timeout(function () {
            $rootScope.$broadcast('destinationInfoEvent', order);
        }, 0);
    }

    function getDateDiff(startDate, endDate) {
        var startDate = new Date(startDate);
        var endDate = new Date(endDate);
        var diff = endDate.getTime() - startDate.getTime();
        var days = Math.floor(diff / (60 * 60 * 24 * 1000));
        var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
        var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
        var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
        return { day: days, hour: hours, minute: minutes, second: seconds };
    }

    $scope.logout = function() {
        $cookies.remove("mybooking");
        $window.location.href = $window.location.href.split('?')[0];
    }

}]);