angular.module('stg.travify.services')
    .factory('errorHandler', ['$rootScope', '$window', 'settingsService', function ($rootScope, $window, settingsService) {
        
        return {
            handleError: function (response) {

                // Handle errors
                if (response.status === 401) {
                    // Unauthorized

                    if (!$rootScope.reauthorized) {
                        // Request a new authentication token
                        settingsService.getAuthenticationToken(true).then(function (resp) {
                            // Reload page
                            $window.location.reload();
                        }, function (data, status) {
                            console.error("Error reauthorizing", data, status);
                        });

                        $rootScope.reauthorized = true;
                    }
                } else if (response.status === 500) {
                    // Error @Travify
                    console.error("Error @Travify", response.status, response.statusText);
                } else {
                    console.error("Error", response.status, response.statusText);
                }

            }
        };
    }]);