var travelSearchApp = angular.module("travelsearch.core");
travelSearchApp.controller('SearchBoxController', ["$rootScope", "$scope", "$cookies", "$filter", "$window", "$timeout", "travelSearchServices", "errorHandler", function ($rootScope, $scope, $cookies, $filter, $window, $timeout, travelSearchServices, errorHandler) {
    var now = new Date();
    $scope.alderObj = [];
    $scope.seasonObj = [
        { alias: "winter", name: "Winter", selected: true },
        { alias: "summer", name: "Summer", selected: false }
    ];

    var updateSearch = true;

    var agesReminder = $('#travelsearch .form-children .change-ages.tooltip-warning').tooltip({
        trigger: 'manual',
        placement: 'bottom'
    });

    var adultsError = $('#travelsearch #voksne.tooltip-error').tooltip({
        trigger: 'manual',
        placement: 'bottom'
    });

    var childrenError = $('#travelsearch #born.tooltip-error').tooltip({
        trigger: 'manual',
        placement: 'bottom'
    });

    var agesError = $('#travelsearch .childrenages .tooltip-error').tooltip({
        trigger: 'manual',
        placement: 'top'
    });

    function convertToCSV(objArray) {
        var str = "";
        for (var i in objArray) {
            str = str + objArray[i];
            if (i < Object.keys(objArray).length - 1) {
                str = str + ",";
            }
        }
        return str;
    }

    $scope.anyChildren = function (){
        if($rootScope.search.born > 0 && !angular.isUndefined($rootScope.search.born)){
            if($scope.showAges){
            angular.element(".maincontainer").addClass("haschildren");
            }
            return true;
        } else {
            angular.element(".maincontainer").removeClass("haschildren");
            return false;
        }
    };

    $scope.setTrigger = function (target) {
        $scope.trigger = target;
    };

    $scope.openDatePicker = function (){
        angular.element("#dato").datepicker("show");
    };

    if (angular.element("#travelsearch").data("hotelseason") != "") {
        $rootScope.search.season = angular.element("#travelsearch").data("hotelseason");
    }
   
    $scope.updateSeason = function (obj) {
       $rootScope.search = obj;

       if (angular.isUndefined($rootScope.search.season)) {
           $rootScope.search.season = $scope.seasonObj[0].alias;
       }

       $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
       
       // compare each season obj alias with search.season and set selected when they match
       angular.forEach($scope.seasonObj, function (obj) {
           obj.selected = obj.alias == $rootScope.search.season;
       });

       angular.element("#dest").attr("disabled", "disabled");

       // if season = undefined/null api will fallback to season = winter.
       travelSearchServices.getDestinations($rootScope.search.season).then(function (response) {
           $scope.destinationObj = response.data;

           if (response.data.length > 0) {
               var hasDestination = $filter("contains")($scope.destinationObj, 'DestinationCode =="' + $rootScope.search.dest + '"');

               if (angular.isUndefined($rootScope.search.dest) || !hasDestination) {
                   var destinations = $filter('filter')($scope.destinationObj, { DestinationType: "REGION" });

                   if (!angular.isUndefined(destinations) && destinations.length > 0) {
                       $rootScope.search.dest = destinations[0].DestinationCode;
                       $rootScope.search.destid = destinations[0].DestinationId;
                   } else {
                       $rootScope.search.dest = $scope.destinationObj[0].DestinationCode;
                       $rootScope.search.destid = $scope.destinationObj[0].DestinationId;
                   }
               }

                if (angular.element('#travelsearch').data('destination') != null || angular.element('#travelsearch').data('country') != null || angular.element('#travelsearch').data('hotelsandprices') != "False") {

                    //Hoteller og Priser
                    if (angular.element('#travelsearch').data('hotelsandprices') && (angular.element('#travelsearch').data('destination') == null && angular.element('#travelsearch').data('country') == null)) {
                        var contentid = angular.element('body').data('pageid');
                        var destinations = $filter('filter')($scope.destinationObj, { DestinationId: contentid });

                        if (destinations.length > 0) {
                            $rootScope.search.dest = destinations[0].DestinationCode;
                            $rootScope.search.destid = destinations[0].DestinationId;
                        }
                    }
                    //Lande
                    if (angular.element('#travelsearch').data('country') != null) {
                        var countryid = angular.element('#travelsearch').data('countryid');
                        var destinations = $filter('filter')($scope.destinationObj, { DestinationId: countryid });

                        if (destinations.length > 0) {
                            $rootScope.search.dest = destinations[0].DestinationCode;
                            $rootScope.search.destid = destinations[0].DestinationId;
                        }
                    }
                    //Destinationer
                    if (angular.element('#travelsearch').data('destination') != null) {
                        var destinationcode = angular.element('#travelsearch').data('destination');
                        var destinations = $filter('filter')($scope.destinationObj, { DestinationCode: destinationcode }, true);
                        
                        if (destinations.length > 0) {
                            $rootScope.search.dest = destinations[0].DestinationCode;
                            $rootScope.search.destid = destinations[0].DestinationId;
                        }
                    }
                      
               } 

               currentDestId = $rootScope.search.destid;
           }


           $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
           
           $timeout(function () {
               $scope.updateDestination($rootScope.search);
           }, 0);

       }).finally(function () {
           angular.element("#dest").removeAttr("disabled");
           initializing = false;
       });
    };

    var standardSelectName = "Vælg";
    if (angular.element("#travelsearch").data("select") != "") {
        standardSelectName = angular.element("#travelsearch").data("select");
    }

    $scope.getDestinationLabel = function (arr, dest) {
        var name = standardSelectName + "...";

        var destination = $filter("filter")(arr, { DestinationCode: dest }, true); // true = strict matching
        if (destination) {
            name = destination[0].DestinationName;
        }
        return name;
    };

    $scope.getTransportLabel = function (arr, code) {
        var name = standardSelectName + "...";

        var transport = $filter("filter")(arr, { TransportCode: code }, true); // true = strict matching
        if (transport) {
            name = transport[0].TransportName;
        }
        return name;
    };

    $scope.getTravelDaysLabel = function (arr ,travellength ,transport, daysLabel, nightsLabel) {
        var name = standardSelectName + "...";

        var displaydays = $filter("filter")(arr, { days: travellength }, true); // true = strict matching
        if (displaydays) {
            name = displaydays[0].transport_display_days + " " + (transport != 'DKK' ? daysLabel : nightsLabel);
        }
        
        return name;
    };

    $scope.updateDestination = function (obj) {
        $rootScope.search = obj;

        var destinations = $filter('filter')($scope.destinationObj, { DestinationCode: $rootScope.search.dest }, true);

        $rootScope.search.destid = destinations[0].DestinationId;
        $rootScope.search.hoteller = destinations[0].DestinationHotelCodes;
        var soldoutwinter = destinations[0].DestinationSoldOutWinter;
        var soldoutsummer = destinations[0].DestinationSoldOutSummer;

        $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

        if(($rootScope.search.season == "winter" && soldoutwinter) || ($rootScope.search.season == "summer" && soldoutsummer)){
            angular.element("#transport").attr("disabled","disabled");
            angular.element("#dage").attr("disabled","disabled");
            angular.element("#dato").attr("disabled","disabled");
            angular.element("#voksne").attr("disabled","disabled");
            angular.element("#born").attr("disabled", "disabled");

            travelSearchServices.getSoldOutHotels($rootScope.search.dest).then(function(response) {
                $rootScope.$broadcast('getHotels', response);
            });
        } else {

            // Until further notice, Self-Drive is the only option
            travelSearchServices.getTransports($rootScope.search.destid, $rootScope.search.season).then(function (response) {  
                if (response.data.length == 0) {
                    angular.element("#transport").attr("disabled","disabled");
                    angular.element("#dage").attr("disabled","disabled");
                    angular.element("#dato").attr("disabled","disabled");
                    angular.element("#voksne").attr("disabled","disabled");
                    angular.element("#born").attr("disabled", "disabled");
                } else {
                    angular.element("#transport").removeAttr("disabled");
                    angular.element("#dage").removeAttr("disabled");
                    angular.element("#dato").removeAttr("disabled");
                    angular.element("#voksne").removeAttr("disabled");
                    angular.element("#born").removeAttr("disabled");

                    $rootScope.searchTransportObj = response.data;

                    var hasTransport = $filter("contains")($scope.searchTransportObj, 'TransportCode =="' + $rootScope.search.transport + '"');

                    if (angular.isUndefined($rootScope.search.transport) || !hasTransport){
                        $rootScope.search.transport = $rootScope.searchTransportObj[0].TransportCode;
                    }

                    $scope.updateTransport($rootScope.search);
                }
            });
        }
    };

    $scope.updateSeason($rootScope.search);

    $scope.updateTransport = function (obj) {
        $rootScope.search = obj;

        $cookies.putObject("search",$rootScope.search,{'path':'/','expires':$rootScope.addDays(now,7)});

        travelSearchServices.getNightsAndDates($rootScope.search.season, $rootScope.search.dest, $rootScope.search.transport).then(function (response) {

            if (response.status !== 200) {
                $rootScope.searchError = true; //show form on hotel page if wrong response
                console.warn("Warning @Travify missing data", response.status, response.statusText);
            }
            
            if (response.data.length === 0 || response.status !== 200) {
                angular.element("#dage").attr("disabled","disabled");
                angular.element("#dato").attr("disabled","disabled");
                angular.element("#voksne").attr("disabled","disabled");
                angular.element("#born").attr("disabled", "disabled");
            } else {
                angular.element("#dage").removeAttr("disabled");
                angular.element("#dato").removeAttr("disabled");
                angular.element("#voksne").removeAttr("disabled");
                angular.element("#born").removeAttr("disabled");

                $rootScope.allNightsAndDates = response.data;

                $rootScope.dageObj = $filter("unique")($rootScope.allNightsAndDates, "days");

                var hasDays = $filter("contains")($rootScope.dageObj, 'days ==' + parseInt($rootScope.search.dage)); 

                if (angular.isUndefined($rootScope.search.dage) || !hasDays) {
                    var hasDays7 = $filter("contains")($rootScope.dageObj, 'days == 7');

                    // set "search.dage" based on transport method  
                    if (hasDays7) {
                        $rootScope.search.dage = 7;
                    } else {
                        $rootScope.search.dage = $rootScope.dageObj[0].days;
                    }
                }
                $scope.updateNights($rootScope.search);
            }
        }).catch(function (response) {
            console.error("Error fetching nights", response.status, response.statusText);

            errorHandler.handleError(response);

            angular.element("#dage").attr("disabled", "disabled");
            angular.element("#dato").attr("disabled", "disabled");
            angular.element("#voksne").attr("disabled", "disabled");
            angular.element("#born").attr("disabled", "disabled");
        });
    };

    $scope.updateNights = function (searchObj) {
        if (searchObj != null) {
            $rootScope.search = searchObj;
            
            $cookies.putObject("search",$rootScope.search,{'path':'/','expires':$rootScope.addDays(now,7)});
         
            $rootScope.datoObj = $filter("where")($rootScope.allNightsAndDates, { days: parseInt($rootScope.search.dage) });

            if (angular.isUndefined($rootScope.search.dato)) {
                $rootScope.search.dato = $rootScope.datoObj[0].date;
            } else {

                var searchDate = $rootScope.search.dato;

                // For locale "da" the date format in search cookie is different,
                // so it needs to parse it to a default datetime format, otherwise "contains" filter will never find this date in "dateObj"
                // and therefore "hasDate" will be false.
                if ($rootScope.longDateFormat == "dd-MM-yyyy") {
                    var dt = $rootScope.parseDate($rootScope.search.dato, $rootScope.longDateFormat.toLowerCase());
                    searchDate = $filter('date')(dt, "yyyy-MM-dd");
                }

                var hasDate = $filter("contains")($rootScope.datoObj, 'date == "' + searchDate + '"');
                if (!hasDate) {
                    $rootScope.search.dato = $rootScope.getClosestDate($rootScope.datoObj, searchDate);
                }
            }
                    
            $scope.updateDates($rootScope.search.dato);
        }

    };

    $scope.updateDates = function (date) {
        $rootScope.search.dato = $filter('date')(date, $rootScope.longDateFormat);

        var searchDate = $rootScope.search.dato;

        if ($rootScope.longDateFormat == "dd-MM-yyyy") {
            var dt = $rootScope.parseDate($rootScope.search.dato, $rootScope.longDateFormat.toLowerCase());
            searchDate = $filter('date')(dt, "yyyy-MM-dd");
        }

        var selectedNightsAndDate = $filter("filter")($rootScope.allNightsAndDates, { days: parseInt($rootScope.search.dage), date: searchDate }, true);

        var transportDisplayDays = selectedNightsAndDate[0].transport_display_days;
        transportDisplayDays = transportDisplayDays + ($rootScope.search.transport === "DKK" ? 1 : 0);

        $rootScope.search.periodid = selectedNightsAndDate[0].period_id;

        $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
        $rootScope.setDatePicker($rootScope.datoObj, "dato", transportDisplayDays);
        $scope.updateAdults($rootScope.search);
    };

    $scope.updateAdults = function(obj) {
        $rootScope.search = obj;

        $cookies.putObject("search",$rootScope.search,{'path':'/','expires':$rootScope.addDays(now,7)});

        if (!angular.isUndefined($rootScope.search.voksne) && $rootScope.search.voksne !== null && $rootScope.search.voksne > -1) {
            adultsError.tooltip("hide");
            $scope.updateChildren($rootScope.search);
        } else {
            adultsError.tooltip("show");
        }
    };
    
    $scope.updateChildren = function(obj){
        $rootScope.search = obj;

        $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

        if (!angular.isUndefined($rootScope.search.born) && $rootScope.search.born !== null && $rootScope.search.born > -1) {
            childrenError.tooltip("hide");
            $scope.createAgesFields($rootScope.search);
        } else {
            childrenError.tooltip("show");
        }

        if ($rootScope.search.born > 0 && !$scope.showAges) {
            var remindUser = $cookies.get("childAgesRemind") == 1 ? false : true;
            var tipIsVisible = $('#travelsearch .form-children .tooltip-warning').next('div.tooltip').hasClass('in');

            if (!tipIsVisible && remindUser) {
                agesReminder.tooltip('show');
            }
        }
        else {
            agesReminder.tooltip('hide');
        }
    };
    
    $scope.minusCount = function ($event, type, min) {
        $event.preventDefault();

        if (type === 'born')
        {
            if (typeof min !== 'undefined') {
                if (parseInt(min) < parseInt($rootScope.search.born)) {
                    $rootScope.search.born = parseInt($rootScope.search.born) - 1;
                }
                else {
                    $rootScope.search.born = parseInt(min);
                }
            }
            else {
                $rootScope.search.born = parseInt($rootScope.search.born) - 1;
            }

            $scope.updateChildren($rootScope.search);
            $scope.checkAges();
            $scope.setTrigger(type);
        }
        else if (type === 'voksne')
        {
            if (typeof min !== 'undefined') {
                if (parseInt(min) < parseInt($rootScope.search.voksne)) {
                    $rootScope.search.voksne = parseInt($rootScope.search.voksne) - 1;
                }
                else {
                    $rootScope.search.voksne = parseInt(min);
                }
            }
            else {
                $rootScope.search.voksne = parseInt($rootScope.search.voksne) - 1;
            }

            $scope.updateAdults($rootScope.search);
            $scope.setTrigger(type);
        }
    };

    $scope.plusCount = function ($event, type, max) {
        $event.preventDefault();

        if (type === 'born')
        {
            if (typeof max !== 'undefined') {
                if (parseInt(max) > parseInt($rootScope.search.born)) {
                    $rootScope.search.born = parseInt($rootScope.search.born) + 1;
                }
                else {
                    $rootScope.search.born = parseInt(max);
                }
            }
            else {
                $rootScope.search.born = parseInt($rootScope.search.born) + 1;
            }

            $scope.updateChildren($rootScope.search);
            $scope.checkAges();
            $scope.setTrigger(type);
        }
        else if (type === 'voksne')
        {
            if (typeof max !== 'undefined') {
                if (parseInt(max) > parseInt($rootScope.search.voksne)) {
                    $rootScope.search.voksne = parseInt($rootScope.search.voksne) + 1;
                }
                else {
                    $rootScope.search.voksne = parseInt(max);
                }
            }
            else {
                $rootScope.search.voksne = parseInt($rootScope.search.voksne) + 1;
            }

            $scope.updateAdults($rootScope.search);
            $scope.setTrigger(type);
        }
    };

    $scope.checkAges = function() {
        if($rootScope.search.born > 0){
            $scope.showAges = true;
        } else {
            $scope.showAges = false;
        }
    };

    $scope.hideAgeWarning = function () {
        agesReminder.tooltip('hide');
    };
   
    if(angular.isUndefined($rootScope.search.alder)){
	    $rootScope.search.alder = {};
    }

    $scope.createAgesFields = function(obj){
        if($rootScope.search.born<$scope.alderObj.length){
            for(var i=$scope.alderObj.length;i>$rootScope.search.born-1;i--) {
            $scope.alderObj.splice(i, 1);
            if(!angular.isUndefined($rootScope.search.alder)){
                delete $rootScope.search.alder[i];
            }
            }
        } else {
            if($rootScope.search.born < $rootScope.numChildren+1){
            for(var i=$scope.alderObj.length;i<$rootScope.search.born;i++) {
                $scope.alderObj.push({age:''});
                if(angular.isUndefined($rootScope.search.alder[i])){
                    $rootScope.search.alder[i] = 9;
                }
            }
            }
        }

        if(!angular.isUndefined($rootScope.search.alder)){
	        if(Object.keys($rootScope.search.alder).length < $scope.alderObj.length){
	            $scope.showAges = true;
	        } 
        }

        $scope.updateAges($rootScope.search);
    };

    $scope.updateAges = function(obj) {
        $rootScope.search = obj;
        $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
        
        var ageIsValid = true;

        angular.forEach($rootScope.search.alder, function (value, key) {
            if (angular.isUndefined(value) || value === null || value > 18 || value < 0) {
                ageIsValid = false;                
            }
        });
        if (ageIsValid) {
            agesError.tooltip("hide");

            search();
        } else {
            agesError.tooltip("show");
        }
    };

   $scope.showAlert = function (transport, textSelfDriveHasValue, textBusHasValue, textFlightHasValue) {

       // get persistent cookie
       $scope.transportAlertInfo = $cookies.getObject("transportAlertInfo");

       // get session cookie
       $scope.transportAlertInfoShown = $cookies.getObject("transportAlertInfoShown");

       // session count of alerts
       var sc_t1AlertShownCount = 0,
           sc_t2AlertShownCount = 0,
           sc_t3AlertShownCount = 0;

       if (!angular.isUndefined($scope.transportAlertInfoShown)) {
           if (!angular.isUndefined($scope.transportAlertInfoShown.alerts)) {
               sc_t1AlertShownCount = $scope.transportAlertInfoShown.alerts[0].count;
               sc_t2AlertShownCount = $scope.transportAlertInfoShown.alerts[1].count;
               sc_t3AlertShownCount = $scope.transportAlertInfoShown.alerts[2].count;
           }
       }

       // alerts hidden count
       var c_t1AlertShownCount = 0,
           c_t2AlertShownCount = 0,
           c_t3AlertShownCount = 0;

       if (!angular.isUndefined($scope.transportAlertInfo)) {
           if (!angular.isUndefined($scope.transportAlertInfo.alerts)) {
               c_t1AlertShownCount = $scope.transportAlertInfo.alerts[0].count;
               c_t2AlertShownCount = $scope.transportAlertInfo.alerts[1].count;
               c_t3AlertShownCount = $scope.transportAlertInfo.alerts[2].count;
           }
       }

       // show if match transport code, has text value, show once per session - three times in total
       if (transport == 'DKK' && textSelfDriveHasValue && sc_t1AlertShownCount < 1 && c_t1AlertShownCount < 3) {
           return true;
       }
       else if ((transport == 'DK' || transport == 'SV') && textBusHasValue && sc_t2AlertShownCount < 1 && c_t2AlertShownCount < 3) {
           return true;
       }
       else if (transport != 'DKK' && transport != 'DK' && transport != 'SV' && textFlightHasValue && sc_t3AlertShownCount < 1 && c_t3AlertShownCount < 3) {
           return true;
       }
       return false;
   };

   $scope.getAlertClass = function (transport) {
       if (transport == "DKK") {
           return "alert-success";
       }
       else if (transport == "DK" || transport == "SV") {
           return "alert-warning";
       }
       else {
           return "alert-info";
       }
   };

   $scope.getTransportType = function (transportCode) {
       // self-drive: 1
       // bus: 2
       // flight: 3

       var transportType = 3;
       if (transportCode == "DKK") {
           transportType = 1;
       }
       else if (transportCode == "DK" || transportCode == "SV") {
           transportType = 2;
       }
       return transportType;
   };

   $scope.closeAlert = function (transport) {
       var id = $scope.getTransportType(transport);

       $scope.transportAlertInfo = $cookies.getObject("transportAlertInfo") || {};
       $scope.transportAlertInfo.alerts = !angular.isUndefined($scope.transportAlertInfo.alerts) && angular.isArray($scope.transportAlertInfo.alerts) ? $scope.transportAlertInfo.alerts : [{ id: 1, count: 0 }, { id: 2, count: 0 }, { id: 3, count: 0 }];

       angular.forEach($scope.transportAlertInfo.alerts, function (obj) {
           obj.count += (obj.id === id ? 1 : 0);
       });

       // set pessistent cookie for hidden alerts
       $cookies.putObject("transportAlertInfo", $scope.transportAlertInfo, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
       
       $scope.transportAlertInfoShown = $cookies.getObject("transportAlertInfoShown") || {};
       $scope.transportAlertInfoShown.alerts = !angular.isUndefined($scope.transportAlertInfoShown.alerts) && angular.isArray($scope.transportAlertInfoShown.alerts) ? $scope.transportAlertInfoShown.alerts : [{ id: 1, count: 0 }, { id: 2, count: 0 }, { id: 3, count: 0 }];

       angular.forEach($scope.transportAlertInfoShown.alerts, function(obj) {
           obj.count += (obj.id === id ? 1 : 0);
       });

       // set session cookie for alerts - count increased for closed alert
       $cookies.putObject("transportAlertInfoShown", $scope.transportAlertInfoShown, { 'path': '/' });
   };

    var search = function () {

        $rootScope.groupTravel = $rootScope.isGroupTravel($rootScope.search.voksne, $rootScope.search.born);


        if ($rootScope.groupTravel || !angular.element('#travel-list').length || !updateSearch) {
           return;
        }

       travelSearchServices.getHotels().then(function (response) {
           $rootScope.$broadcast('getHotels', response);
       });

       updateSearch = false;
   };

   $scope.submit = function() {
       if ($scope.travelsearch.$valid) {
         // set session cookie, so user don't see remind tooltip further in the session
         $cookies.put("childAgesRemind", 1, { 'path': '/' });

        var queryString = "";
        if(!angular.isUndefined($rootScope.search))
        {
            queryString = "?";

            if ($rootScope.search.season) {
                queryString = queryString + "saeson=" + $rootScope.search.season;
            }

            if ($rootScope.search.transport) {
                queryString = queryString + "&transport=" + $rootScope.search.transport;
            }

            if ($rootScope.search.dage) {
                queryString = queryString + "&dage=" + $rootScope.search.dage;
            }

            if ($rootScope.search.dato) {
                queryString = queryString + "&dato=" + $rootScope.search.dato;
            }

            if ($rootScope.search.voksne) {
                queryString = queryString + "&voksne=" + $rootScope.search.voksne;
            }

            if ($rootScope.search.born) {
                queryString = queryString + "&born=" + $rootScope.search.born;
            }

            if ($rootScope.search.alder) {
                if (angular.isObject($rootScope.search.alder)) {
                    var csvString = convertToCSV($rootScope.search.alder);
                    if (csvString !== "") {
                        queryString = queryString + "&alder=" + csvString;
                    }
                }
            }
        }

        $window.location.href = angular.element("#dest :selected").data("url") + queryString;
      }
   };
}]);

travelSearchApp.controller('HotelListController', ["$rootScope", "$scope", "$cookies", "$filter", "travelSearchServices", "utilsService", "$timeout", "errorHandler", function ($rootScope, $scope, $cookies, $filter, travelSearchServices, utilsService, $timeout, errorHandler){
    var now = new Date();

    $scope.loadProgress = false;

    $timeout(function () {
        $scope.loadProgress = true;
    }, 15000);

    //$scope.loading = true;
    $scope.error = false;
    $scope.errorType = null;

    $scope.hotellist = {};
    
    $scope.hotellist.dage = $rootScope.search.dage;
    $scope.hotellist.voksne = $rootScope.search.voksne;
    $scope.hotellist.born = $rootScope.search.born;
    $scope.hotellist.dato = $rootScope.search.dato;
    $scope.hotellist.transport = $rootScope.search.transport;
    $scope.hotellist.sorter = $rootScope.search.sorter;

    //test for searchError
    $timeout(function () {
        if ($rootScope.searchError === true) {
            $scope.$apply(function () {
                $scope.error = true;
                $scope.errorType = 204;
            });
        }
    }, 1000);

    $scope.setNewDate = function (date) {
        var formattedDate = $filter('date')(date, $rootScope.longDateFormat);

        $scope.hotellist.dato = formattedDate;
        $rootScope.search.dato = formattedDate;

        var selectedNightsAndDate = $filter("filter")($rootScope.allNightsAndDates, { days: parseInt($rootScope.search.dage), date: date }, true);

        $rootScope.search.periodid = selectedNightsAndDate[0].period_id;

        $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

        $scope.loadComplete = false;
        $scope.loadProgress = false;

        $timeout(function () {
            $scope.loadProgress = true;
        }, 15000);

        travelSearchServices.getHotels().then(function (response) {
            $rootScope.$broadcast('getHotels', response, { updateTravelDate: false });
        });       
    };

    $scope.isDateActive = function (date, searchdate, index) {
        date = $filter('date')(date, $rootScope.longDateFormat);

        if (date == searchdate) {
            return true;
        } else {
            return false;
        }
    };

    var buildQueryString = function () {
        var queryString = "";
        if (!angular.isUndefined($rootScope.search)) {
            queryString = "?";

            if ($rootScope.search.season) {
                queryString = queryString + "saeson=" + $rootScope.search.season;
            }

            if ($rootScope.search.transport) {
                queryString = queryString + "&transport=" + $rootScope.search.transport;
            }

            if ($rootScope.search.dage) {
                queryString = queryString + "&dage=" + $rootScope.search.dage;
            }

            if ($rootScope.search.dato) {
                queryString = queryString + "&dato=" + $rootScope.search.dato;
            }

            if ($rootScope.search.voksne) {
                queryString = queryString + "&voksne=" + $rootScope.search.voksne;
            }

            if ($rootScope.search.born) {
                queryString = queryString + "&born=" + $rootScope.search.born;
            }

            if ($rootScope.search.alder && $rootScope.search.born && $rootScope.search.born > 0) {
                if (angular.isObject($rootScope.search.alder)) {
                    var csvString = utilsService.convertToCSV($rootScope.search.alder);
                    if (csvString !== "") {
                        queryString = queryString + "&alder=" + csvString;
                    }
                }
            }
        }

        $scope.urlQueryString = queryString;
    };

    var initTravelDates = function (traveldates) {
        var s = $("#traveldates").attr("data-slide");
        var itemscount = traveldates.length;
        var index = 0;

        $('.left, .right').click(function () {
            var condition = $(this).hasClass("left");
            if (condition)
                click(0);
            else
                click(1);
        });

        var resizeTimer;
        var docWidth = document.querySelector("#width");
        $(window).on('resize', function (e) {
            clearTimeout(resizeTimer);
            resizeTimer = setTimeout(function () {
                if (docWidth != document.querySelector("#width")) {
                    docWidth = document.querySelector("#width");
                    ResCarouselSize();
                }
            }, 250);

        });

        //this function define the size of the items
        function ResCarouselSize() {
            var incno = 0;
            var sampwidth = $("#traveldates").width();
            var bodyWidth = $('body').width();
            var btnParentSb = $("#traveldates").attr("data-items");
            var itemsSplit = btnParentSb.split(',');
            
            if (bodyWidth >= 1200) {
                incno = itemsSplit[3];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 992) {
                incno = itemsSplit[2];
                itemWidth = sampwidth / incno;
            }
            else if (bodyWidth >= 768) {
                incno = itemsSplit[1];
                itemWidth = sampwidth / incno;
            }
            else {
                incno = itemsSplit[0];
                itemWidth = sampwidth / incno;
            }

            var allElementsWidth = itemWidth * itemscount;

            $("#traveldates .multicarousel-inner").css({'width': allElementsWidth });
            $("#traveldates .multicarousel-inner .item").each(function () {
                $(this).outerWidth(itemWidth);
            });


            // Center active item
            var activedate = "";

            if ($rootScope.longDateFormat == "dd-MM-yyyy") {
                activedate = $scope.hotellist.dato.split("-").reverse().join("-");
            } else {
                activedate = $scope.hotellist.dato;
            }

            index = $scope.hotellist.traveldates.map(function (traveldate) { return traveldate.date; }).indexOf(activedate);

            var translateXval = itemWidth * (index - 1);

            if (translateXval <= itemWidth * s) {
                translateXval = 0;
            }

            if (translateXval >= allElementsWidth - (itemWidth * incno)) {
                translateXval = allElementsWidth - (itemWidth * incno);
            }
            $("#traveldates .multicarousel-inner").css('transform', 'translateX(' + -translateXval + 'px)');
        }


        //this function used to move the items
        function ResCarousel(e, s) {
            var translateXval = '';
            var divStyle = $("#traveldates .multicarousel-inner").css('transform');
            var values = divStyle.match(/-?[\d\.]+/g);

            var xds = Math.abs(values[4]);
            if (e == 0) {
                translateXval = parseInt(xds) - parseInt(itemWidth * s);

                if (translateXval <= itemWidth / 2) {
                    translateXval = 0;
                }
            }
            else if (e == 1) {
                var itemsCondition = $("#traveldates .multicarousel-inner").width() - $("#traveldates").width();
                translateXval = parseInt(xds) + parseInt(itemWidth * s);

                if (translateXval >= itemsCondition - itemWidth / 2) {
                    translateXval = itemsCondition;
                }
            }
            $("#traveldates .multicarousel-inner").css('transform', 'translateX(' + -translateXval + 'px)');
        }

        //It is used to get some elements from btn
        function click(d) {
            ResCarousel(d, s);
        }

        ResCarouselSize();

        $("#traveldates").show();
    }

    function getPersons(adults, children, totalPrice) {

        var arr = [];

        var totalPersons = parseInt(adults) + parseInt(children),
            pricePerPerson = parseFloat(totalPrice) / totalPersons;

        for (var i = 0; i < adults; i++) {
            var a = {
                adult: true,
                price: pricePerPerson,
                suffix: i + 1
            };
            arr.push(a);
        }

        for (var j = 0; j < children; j++) {
            var c = {
                adult: false,
                price: pricePerPerson,
                suffix: j + 1
            };
            arr.push(c);
        }

        return arr;
    }

    $scope.showPriceIncludes = function ($event, hotel) {

        $event.preventDefault();
        $event.stopPropagation();

        var content = "";

        var priceIncludeContent = angular.element($event.currentTarget).closest(".hotel").find(".price-includes-content");

        content += "<ul class=\"list-inline\">";
        
        for (var i = 0; i < hotel.priceincludes.length; i++) {
            content += "<li><i class=\"fa fa-check\"></i> " + hotel.priceincludes[i] + "</li>";
        }
        content += "</ul>";
        
        content += priceIncludeContent.html();
     
        var modalBody = $("#price-include-modal").find(".modal-body");
        if (modalBody) {
            modalBody.empty().append(content);
        }

        $("#price-include-modal").modal("show");
    };
    
    $scope.sortBy = function (sortValue) {
        travelSearchServices.setCookie("search", "sorter", sortValue, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
    };

    $scope.$on('getHotels', function (event, response) {
        if (angular.isUndefined(response.data) || response.data.length == 0) {
            console.warn("Missing data for booking", response.status, response.statusText);
            $scope.error = true;
            $scope.errorType = response.status;
            $scope.errorResponse = response.statusText;
        } else {

            $scope.hotellist.dato = $rootScope.search.dato;

            $scope.hotellist.travels = $filter("filter")(response.data, { soldout: false }, true);
            $scope.hotellist.soldoutTravels = $filter("filter")(response.data, { soldout: true }, true);

            var firstTravel = null;
            if (!angular.isUndefined($scope.hotellist) && $scope.hotellist !== null) {
                if (!angular.isUndefined($scope.hotellist.travels[0]) && $scope.hotellist.travels[0] !== null) {
                    firstTravel = $scope.hotellist.travels[0].transportinfo;
                }
            }

            if (firstTravel !== null) {
                $scope.hotellist.transportinfo = $scope.hotellist.travels[0].transportinfo;
                $scope.hotellist.departuredate = $scope.hotellist.travels[0].departuredate;
                $scope.hotellist.returndate = $scope.hotellist.travels[0].returndate;
            }

            // Temporary measures regarding soldout hotels

            var summer2021 = new Date(2021, 03, 01);
            var winter2022 = new Date(2021, 09, 01);
            var departureDate = new Date($scope.hotellist.departuredate);
            $scope.hotellist.soldout = {};

            if (departureDate < summer2021) {
                $scope.hotellist.soldout.soldout = true;
            }

            if (departureDate > winter2022) {
                if (now < winter2022) {
                    $scope.hotellist.soldout.iscoming = true;
                } else {
                    $scope.hotellist.soldout.soldout = true;
                }
            }

            // End of temporary measures

            $scope.travelFilters = {};
            if (firstTravel !== null) {
                $scope.travelFilters.facilities = $scope.hotellist.travels[0].facilities;
                $scope.travelFilters.distances = $scope.hotellist.travels[0].distances;
            }

            buildQueryString();

            var hotelMarkings = $filter("unique")($scope.hotellist.travels, 'hotelmarking'); //unique filter;
            hotelMarkings = $filter("map")(hotelMarkings, 'hotelmarking');
            hotelMarkings = hotelMarkings.filter(Boolean); // exclude empty strings

            if (!angular.isUndefined($scope.hotellist) && $scope.hotellist !== null) {
                if (!angular.isUndefined($scope.hotellist.transportinfo) && $scope.hotellist.transportinfo !== null) {
                    $scope.hotellist.transportinfo.departureduration = $rootScope.getDateDiff($scope.hotellist.transportinfo.departuretimedeparture, $scope.hotellist.transportinfo.arrivaltimedeparture);
                    $scope.hotellist.transportinfo.returnduration = $rootScope.getDateDiff($scope.hotellist.transportinfo.departuretimereturn, $scope.hotellist.transportinfo.arrivaltimereturn);
                }
            }
        
            var markings = [];
            if (hotelMarkings && hotelMarkings.length > 0)
            {
                markings = hotelMarkings.map(function (val) {
                    return { key: replaceHotelMarkingChars(val), value: val };
                });
            }

            $scope.travelFilters.hotelmarkings = markings;

            $scope.travelFilters.ownhotels = $filter("filter")($scope.hotellist.travels, { ownhotel: true }).length > 0;

            $scope.travelFilters.standards = [2,3,4,5];

            var sorting = $rootScope.getFilterSorting($rootScope.filters.sorter);

            // Filter travels on init
            $scope.hotellist.filteredTravels = $filter("travelFilter")($scope.hotellist.travels, $scope.filters);
            $scope.hotellist.filteredTravels = $filter("orderBy")($scope.hotellist.filteredTravels, sorting);

            $scope.hotellist.filteredOutTravels = $scope.hotellist.travels.filter(function (obj) {
                return !$scope.hotellist.filteredTravels.includes(obj);
            });

            angular.forEach($scope.hotellist.travels, function (obj) {
                obj.persons = getPersons($scope.hotellist.voksne, $scope.hotellist.born, obj.price);
            });
        
            if (angular.element('#traveldates').length) {            
                $("#traveldates").hide();
                // Ensure travel dates are unique and order by date in ascending order.
                $scope.hotellist.traveldates = $filter('unique')($rootScope.datoObj, 'date');
                $scope.hotellist.traveldates = $filter('orderBy')($scope.hotellist.traveldates, 'date');
                initTravelDates($scope.hotellist.traveldates);
            }

            var favCookieValue = $cookies.get("favorit");
            if (!angular.isUndefined(favCookieValue)) {
                $scope.hotellist.favorites = favCookieValue;
            }

            pushToDataLayer($scope.hotellist);
        }

        $scope.loadComplete = true;
        $scope.loadProgress = false;
    });

    function replaceHotelMarkingChars(value) {
        return value
            .replace("&", "")
            .replace("!", "")
            .replace(",", "")
            .replace("|", "")
            .replace(">", "")
            .replace("<", "")
            .replace(" ", "")
            .replace("'", "")
            .replace(".", "")
            .replace("%", "")
            .replace("/", "")
            .replace("\\", "")
            .replace(":", "")
            .replace("#", "")
            .replace("*", "")
            .replace("?", "")

            .replace("æ", "_ae1_")
            .replace("Æ", "_ae2_")
            .replace("ø", "_oe1_")
            .replace("Ø", "_oe2_")
            .replace("å", "_aa1_")
            .replace("Å", "_aa2_")

            .replace("ä", "_ae3_")
            .replace("Ä", "_ae4_")

            .replace("ö", "_oe3_")
            .replace("Ö", "_oe4_")

            .replace("ü", "_ue1_")
            .replace("Ü", "_ue2_")

            .replace("ß", "_ss_")
            .toLowerCase();
    }

    function pushToDataLayer(hotellist) {
        var travelsearch = $("#travelsearch");

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "travelsearch",
            "travelCheckinDate": $filter('date')(hotellist.departuredate, "yyyy-MM-dd"),
            "travelCheckoutDate": $filter('date')(hotellist.returndate, "yyyy-MM-dd"),
            "travelDays": hotellist.dage,
            "travelContentIds": hotellist.travels.map(function (travels) { return travels.code; }),
            "travelCity": travelsearch.data("destination-name"),
            "travelCountry": travelsearch.data("country"),
            "travelAdults": hotellist.voksne,
            "travelChildren": hotellist.born,
            "travelTotalPersons": parseInt(hotellist.voksne) + parseInt(hotellist.born),
            "travelTransport": hotellist.transport
        });
    };

}]);

travelSearchApp.controller('HotelController', ["$rootScope", "$scope", "$cookies", "$filter", "$timeout", "$window", "travelSearchServices", "errorHandler", function ($rootScope, $scope, $cookies, $filter, $timeout, $window, travelSearchServices, errorHandler) {
    var init = true;

    $timeout(function () {
        if ($rootScope.searchError === true) {
            $scope.$apply(function () {
                $scope.hotel.noresults = true;
            });
        }
    }, 1000);

    var adultsError = $('#smallsearch #hotel-voksne.tooltip-error').tooltip({
        trigger: 'manual',
        placement: 'bottom'
    });

    var childrenError = $('#smallsearch #hotel-born.tooltip-error').tooltip({
        trigger: 'manual',
        placement: 'bottom'
    });

    var agesError = $('#smallsearch .childrenages .tooltip-error').tooltip({
        trigger: 'manual',
        placement: 'top'
    });

    function convertToCSV(objArray) {
        var str = "";
        for (var i in objArray) {
            str = str + objArray[i];
            if (i < Object.keys(objArray).length - 1) {
                str = str + ",";
            }
        }
        return str;
    };

    function createAgestring(hotel) {
        // Assume at least 1 adult
        var ageString = "30";

        for (var i = 1; hotel.voksne > i; i++) {
            ageString += ",30";
        }

        if (!angular.isUndefined(hotel.born) && hotel.born != null && hotel.born > 0) {
            ageString += "," + convertToCSV(hotel.alder);
        }
        return ageString;
    }

    function updatePriceButton() {
        var totalprice = parseFloat($scope.hotel.totalprice);
        var discountprice = $filter('abs')($scope.hotel.discountprice);
        var pricebefore = totalprice + discountprice;
        var offer = $scope.hotel.offer === true;

        var totalpriceString = $filter('number')(totalprice, '0') + ",-";
        var discountpriceString = $filter('number')(discountprice, '0') + ",-";
        var pricebeforeString = $filter('number')(pricebefore, '0') + ",-";

        angular.element(".hotelpricebutton .price .value").text(totalpriceString);
        angular.element(".hotelpricebutton .price").removeClass("hidden");

        if (offer && discountprice > 0)
        {
            angular.element(".discount-badge .discountprice").text(discountpriceString);
            angular.element(".discount-badge").removeClass("hidden");

            angular.element(".hotelpricebutton .price-before .value").text(pricebeforeString);
            angular.element(".hotelpricebutton .price-before").removeClass("hidden");
            angular.element(".hotelpricebutton .price").addClass("save");
        }
        else
        {
            angular.element(".discount-badge .discountprice").empty();
            angular.element(".discount-badge").addClass("hidden");

            angular.element(".hotelpricebutton .price-before .value").empty();
            angular.element(".hotelpricebutton .price-before").addClass("hidden");
            angular.element(".hotelpricebutton .price").removeClass("save");
        }
    }

    var now = new Date();
    $scope.hotel = {};
    $scope.hotel.priceincludes = [];

    $scope.alderObj = [];
    $scope.seasonObj = [
      { alias: "winter", name: "Winter", selected: true },
      { alias: "summer", name: "Summer", selected: false }
    ];

    $scope.hotel.destid = travelSearchServices.getCookie("search", "destid");
    $scope.hotel.transport = travelSearchServices.getCookie("search", "transport");
    $scope.hotel.dage = travelSearchServices.getCookie("search", "dage");
    $scope.hotel.dato = travelSearchServices.getCookie("search", "dato");
    $scope.hotel.voksne = travelSearchServices.getCookie("search", "voksne");
    $scope.hotel.born = travelSearchServices.getCookie("search", "born");
    $scope.hotel.alder = travelSearchServices.getCookie("search", "alder");

    $scope.hotel.dest = angular.element("#hotel-search").data("dest");
    $scope.hotel.hotel = angular.element("#hotel-search").data("hotel");
    $scope.hotel.destid = angular.element("#hotel-search").data("destid");

    if ($rootScope.getParameterByName("transport_id")) {
        $scope.hotel.transport_id = $rootScope.getParameterByName("transport_id");
    }

    if (angular.isUndefined($scope.hotel.voksne) || $scope.hotel.voksne === null) {
        $scope.hotel.voksne = 2;
    }

    if (angular.isUndefined($scope.hotel.born) || $scope.hotel.born === null) {
        $scope.hotel.born = 0;
    }
    
    if(angular.element('.form-pricechoice').length){
      $scope.hotel.vispris = travelSearchServices.getCookie("search","vispris");
   }

    $scope.anyChildren = function (){
      if($scope.hotel.born > 0 && !angular.isUndefined($scope.hotel.born)){
         return true;
      } else {
         return false;
      }
   };

    $scope.openDatePicker = function (){
        angular.element("#hotel-dato").datepicker("show");
   };

    travelSearchServices.getTransports($scope.hotel.destid, $rootScope.search.season).then(function (response) {
        
        if(response.data.length == 0){
            angular.element("#hotel-transport").attr("disabled","disabled");
            angular.element("#hotel-dage").attr("disabled","disabled");
            angular.element("#hotel-dato").attr("disabled","disabled");
            angular.element("#hotel-voksne").attr("disabled","disabled");
            angular.element("#hotel-born").attr("disabled","disabled");
        } else {
            angular.element("#hotel-transport").removeAttr("disabled");
            angular.element("#hotel-dage").removeAttr("disabled");
            angular.element("#hotel-dato").removeAttr("disabled");
            angular.element("#hotel-voksne").removeAttr("disabled");
            angular.element("#hotel-born").removeAttr("disabled");

            $scope.hotelTransportObj = response.data;

            var hasTransport = $filter("contains")($scope.hotelTransportObj, 'TransportCode =="' + $scope.hotel.transport + '"');

            if (!hasTransport){
                $scope.hotel.transport = $scope.hotelTransportObj[0].TransportCode;
            }
         
            $scope.updateTransport($scope.hotel);
        }
    });

    $scope.updateTransport = function (obj) {
        $scope.hotel = obj;

        travelSearchServices.getNightsAndDates($rootScope.search.season, $scope.hotel.dest, $scope.hotel.transport).then(function (response) {

            if (response.status !== 200) {
                $rootScope.searchError = true;
                console.warn("Missing data for nights", response.status, response.statusText);
            }

            if (response.data.length === 0 || response.status !== 200) {
                angular.element("#hotel-dage").attr("disabled", "disabled");
                angular.element("#hotel-dato").attr("disabled", "disabled");
                angular.element("#hotel-voksne").attr("disabled", "disabled");
                angular.element("#hotel-born").attr("disabled", "disabled");
            } else {
                angular.element("#hotel-dage").removeAttr("disabled");
                angular.element("#hotel-dato").removeAttr("disabled");
                angular.element("#hotel-voksne").removeAttr("disabled");
                angular.element("#hotel-born").removeAttr("disabled");

                $scope.allNightsAndDates = response.data;

                $scope.dageObj = $filter("unique")($scope.allNightsAndDates, "days");

                var hasDays = $filter("contains")($scope.dageObj, 'days ==' + parseInt($scope.hotel.dage));

                if (angular.isUndefined($scope.hotel.dage) || !hasDays) {
                    var hasDays7 = $filter("contains")($scope.dageObj, 'days == 7');

                    // set "search.dage" based on transport method  
                    if (hasDays7) {
                        $scope.hotel.dage = 7;
                    } else {
                        $scope.hotel.dage = $scope.dageObj[0].days;
                    }
                }

                $rootScope.search.dage = $scope.hotel.dage;
                $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

                $scope.updateNights($scope.hotel);
            }
        }).catch(function (response) {
            console.error("Error fetching nights", response.status, response.statusText);

            errorHandler.handleError(response);

            angular.element("#hotel-dage").attr("disabled", "disabled");
            angular.element("#hotel-dato").attr("disabled", "disabled");
            angular.element("#hotel-voksne").attr("disabled", "disabled");
            angular.element("#hotel-born").attr("disabled", "disabled");
        });
    };

    $scope.updateNights = function (obj) {
        if (obj != null) {
            $scope.hotel = obj;
                     
            $scope.datoObj = $filter("where")($scope.allNightsAndDates, { days: parseInt($scope.hotel.dage) });

            if (angular.isUndefined($scope.hotel.dato)) {
                $scope.hotel.dato = $scope.datoObj[0].date;
            } else {
                var searchDate = $scope.hotel.dato;

                // For locale "da" the date format in search cookie is different,
                // so it needs to parse it to a default datetime format, otherwise "contains" filter will never find this date in "dateObj"
                // and therefore "hasDate" will be false.
                if ($rootScope.longDateFormat == "dd-MM-yyyy") {
                    var dt = $rootScope.parseDate($scope.hotel.dato, $rootScope.longDateFormat.toLowerCase());
                    searchDate = $filter('date')(dt, "yyyy-MM-dd");
                }

                var hasDate = $filter("contains")($scope.datoObj, 'date == "' + searchDate + '"');
                if (!hasDate) {
                    $scope.hotel.dato = $rootScope.getClosestDate($scope.datoObj, searchDate);
                }
            }

            $rootScope.search.dato = $scope.hotel.dato;
            $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
                    
            $scope.updateDates($scope.hotel.dato);
        }
    };

    $scope.updateDates = function (date) {
        $scope.hotel.dato = $filter('date')(date, $rootScope.longDateFormat);

        $rootScope.search.dato = $scope.hotel.dato;
        $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

        var searchDate = $scope.hotel.dato;

        if ($rootScope.longDateFormat == "dd-MM-yyyy") {
            var dt = $rootScope.parseDate($scope.hotel.dato, $rootScope.longDateFormat.toLowerCase());
            searchDate = $filter('date')(dt, "yyyy-MM-dd");
        }

        var selectedNightsAndDate = $filter("filter")($scope.allNightsAndDates, { days: parseInt($scope.hotel.dage), date: searchDate }, true);

        var transportDisplayDays = selectedNightsAndDate[0].transport_display_days;
        transportDisplayDays = transportDisplayDays + ($scope.hotel.transport === "DKK" ? 1 : 0);

        $scope.hotel.periodid = selectedNightsAndDate[0].period_id;

        $rootScope.setDatePicker($scope.datoObj, "hotel-dato", transportDisplayDays);
        $scope.updateAdults($scope.hotel);
    };

    $scope.updateAdults = function(obj) {
        $scope.hotel = obj;
        
        if (!angular.isUndefined($scope.hotel.voksne) && $scope.hotel.voksne !== null && $scope.hotel.voksne > -1) {
            adultsError.tooltip("hide");
            $scope.updateChildren($scope.hotel);

            $rootScope.search.voksne = $scope.hotel.voksne;
            $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
        } else {
            adultsError.tooltip("show");
        }
    };

    $scope.updateChildren = function(obj){
        $scope.hotel = obj;

        if (!angular.isUndefined($scope.hotel.born) && $scope.hotel.born !== null && $scope.hotel.born > -1) {
            childrenError.tooltip("hide");
            $scope.createAgesFields($rootScope.search)

            $rootScope.search.born = $scope.hotel.born;
            $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
        } else {
            childrenError.tooltip("show");
        }
    };
   
    if(angular.isUndefined($scope.hotel.alder)){
	   $scope.hotel.alder = {};
    }

    $scope.createAgesFields = function(obj){
      if($scope.hotel.born<$scope.alderObj.length){
         for(var i=$scope.alderObj.length;i>$scope.hotel.born-1;i--) {
            $scope.alderObj.splice(i, 1);
            if(!angular.isUndefined($scope.hotel.alder)){
               delete $scope.hotel.alder[i];
            }
         }
      } else {
         if($scope.hotel.born < $rootScope.numChildren+1){
            for(var i=$scope.alderObj.length;i<$scope.hotel.born;i++) {
               $scope.alderObj.push({age:''});
               if(angular.isUndefined($scope.hotel.alder[i])){
                  $scope.hotel.alder[i] = 0;
               }
            }
         }
      }
      $scope.updateAges($scope.hotel);
   }

    $scope.updateAges = function(obj) {
        $scope.hotel = obj;

        if(angular.element('.form-pricechoice').length){
            $rootScope.search.vispris = $scope.hotel.vispris;
            $cookies.putObject("search",$rootScope.search,{'path':'/','expires':$rootScope.addDays(now,7)});
        }

        var ageIsValid = true;
        angular.forEach($scope.hotel.alder, function (value, key) {
            if (angular.isUndefined(value) || value === null || value > 18 || value < 0) {
                ageIsValid = false;
            }
        });
        if (ageIsValid) {
            agesError.tooltip("hide");

            $rootScope.search.alder = $scope.hotel.alder;
            $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });

            $scope.getHotel(false);
        } else {
            agesError.tooltip("show");
        }

    };

    $scope.updateTravel = function (period_id, room_string, transport_id, price, discount_price, offer) {
        $scope.hotel.option = period_id + "-" + room_string;
        $scope.hotel.totalprice = price;

        // Update discount price from room option when changing selected radiobutton option.
        $scope.hotel.discountprice = discount_price;
        $scope.hotel.offer = offer;

        updatePriceButton();

        $scope.hotel.period_id = period_id;
        $scope.hotel.room_string = room_string;
        $scope.hotel.transport_id = transport_id;

        $scope.hotel.persons = getPersons($scope.hotel.voksne, $scope.hotel.born, $scope.hotel.totalprice);
    };

    $scope.getHotel = function (doSearch) {

        if (!doSearch && !init) {
            // Make search button visible
            angular.element("#smallsearch .btn-submit").removeClass("hidden");
        }

        $scope.hotel.groupTravel = $rootScope.isGroupTravel($scope.hotel.voksne, $scope.hotel.born);
        if ($scope.hotel.groupTravel || (!doSearch && !init)) {
            return;
        }

        $scope.loadProgress = false;

        // Update loading progress
        $timeout(function () {
            $scope.loadProgress = true;
        }, 15000);

        travelSearchServices.getHotel($scope.hotel, $rootScope.search.season).then(function (response) {

            $scope.loadProgress = false;

            $scope.hotel.roomsoptions = response.data;

            if (!angular.isUndefined($scope.hotel.roomsoptions) && $scope.hotel.roomsoptions !== null && $scope.hotel.roomsoptions.length > 0)
            {
                // Sort room options by current_week price.
                // We can't use orderBy filter because these prices are string values and we need to sort then as float/decimal values.
                $scope.hotel.roomsoptions = $scope.hotel.roomsoptions.sort(function (a, b) {
                    if (parseFloat(a.current_week.price) > parseFloat(b.current_week.price))
                        return 1;
                    if (parseFloat(a.current_week.price) < parseFloat(b.current_week.price))
                        return -1;
                    return 0;
                });
            }

            $scope.hotel.transportinfo = {};

            if ($scope.hotel.roomsoptions.length > 0) {
                $scope.hotel.previous_week_number = "";
                $scope.hotel.previous_week_departure_date = "";
                $scope.hotel.previous_week_return_date = "";

                $scope.hotel.current_week_number = "";
                $scope.hotel.current_week_departure_date = "";
                $scope.hotel.current_week_return_date = "";

                $scope.hotel.next_week_number = "";
                $scope.hotel.next_week_departure_date = "";
                $scope.hotel.next_week_return_date = "";

                var firstSelected = $scope.hotel.roomsoptions[0];

                $scope.hotel.transportinfo.code = firstSelected.transport_code;

                $scope.hotel.departuredate = firstSelected.current_week.departure_date;
                $scope.hotel.transportinfo.departuretimedeparture = firstSelected.departure_time_for_departure;
                $scope.hotel.transportinfo.arrivaltimedeparture = firstSelected.arrival_time_for_departure;
                $scope.hotel.transportinfo.departureairportdeparture = firstSelected.departure_airport_for_departure;
                $scope.hotel.transportinfo.departureairportcodedeparture = firstSelected.departure_airport_code_for_departure;
                $scope.hotel.transportinfo.arrivalairportdeparture = firstSelected.arrival_airport_for_departure;
                $scope.hotel.transportinfo.arrivalairportcodedeparture = firstSelected.arrival_airport_code_for_departure;

                $scope.hotel.returndate = firstSelected.current_week.return_date;
                $scope.hotel.transportinfo.departuretimereturn = firstSelected.departure_time_for_return;
                $scope.hotel.transportinfo.arrivaltimereturn = firstSelected.arrival_time_for_return;
                $scope.hotel.transportinfo.departureairportreturn = firstSelected.departure_airport_for_return;
                $scope.hotel.transportinfo.departureairportcodereturn = firstSelected.departure_airport_code_for_return;
                $scope.hotel.transportinfo.arrivalairportreturn = firstSelected.arrival_airport_for_return;
                $scope.hotel.transportinfo.arrivalairportcodereturn = firstSelected.arrival_airport_code_for_return;

                $scope.hotel.transportinfo.departureduration = $rootScope.getDateDiff(firstSelected.departure_time_for_departure, firstSelected.arrival_time_for_departure);
                $scope.hotel.transportinfo.returnduration = $rootScope.getDateDiff(firstSelected.departure_time_for_return, firstSelected.arrival_time_for_return);

                $scope.hotel.option = firstSelected.current_week.period_id + "-" + firstSelected.rooms.room_string;
                $scope.hotel.totalprice = firstSelected.current_week.price;
                $scope.hotel.discountprice = firstSelected.discount_price;
                $scope.hotel.offer = firstSelected.offer;

                $scope.hotel.period_id = firstSelected.current_week.period_id;
                $scope.hotel.room_string = firstSelected.rooms.room_string;
                $scope.hotel.transport_id = firstSelected.transport_id;

                $scope.hotel.age_string = createAgestring($scope.hotel);

                $scope.hotel.persons = getPersons($scope.hotel.voksne, $scope.hotel.born, $scope.hotel.totalprice);

                updatePriceButton();

                insertStructuredData($scope.hotel.roomsoptions);

                pushToDataLayer($scope.hotel);

                init = false;

                if ($rootScope.getParameterByName("scroll")) {
                    var roomOptions = angular.element('#hotel-search');
                    roomOptions[0].scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
                
            }   

            // ensures DOM is ready before looking for elements
            $timeout(function () {
                var bookUrl = $('#hotel-search input:radio:checked').data("bookurl"),
                    bookBtn = angular.element("#hotel-search .hotelsearchtotal a.book-button").attr("href", bookUrl);

                if (!angular.isUndefined($cookies.get("salg"))) {
                    bookBtn.attr("target", "_blank");
                }

                var hotelCode = angular.element("#travelsearch").data("hotelkode");
             
            }, 0);
         
      });
    }

    function generateStructuredData(offer, room) {
        var destination = $('#travelsearch').data('destination-name');
        var hotel = $('#travelsearch').data('hotelname');

        var nameString = offer.travel_length + " nætter, " + $scope.hotel.voksne + " voksne ";
        if ($scope.hotel.born > 0) {
            nameString += $scope.hotel.born + " børn ";
        }
        nameString += "fra kr. " + offer.price;

        
        var travelJSON = {
            image: window.location.href,
            startDate: offer.departure_date,
            endDate: offer.return_date,
            name: nameString,
            description: "Skiferie i " + destination,
            location: {
                "@type": "Place",
                name: hotel,
                address: {
                    "@type": "PostalAddress",
                    addressLocality: destination
                }
            },
            performer: "Gæster",
            offers: {
                "@type": "Offer",
                name: room.rooms_description,
                price: offer.price,
                priceCurrency: "DKK",
                availability: "https://schema.org/InStock",
                validFrom: offer.departure_date,
                url: window.location.href
            }
        };        

        return travelJSON;
    }

    function insertStructuredData(roomoptions) {

        try {
            var jsonld = JSON.parse(document.getElementById("structureddata").innerText);

            var travelJSON = [];

            for (var i in roomoptions) {
                if (roomoptions[i].previous_week && roomoptions[i].previous_week.period_id) {
                    travelJSON.push(generateStructuredData(roomoptions[i].previous_week, roomoptions[i].rooms));
                }
                if (roomoptions[i].current_week && roomoptions[i].current_week.period_id) {
                    travelJSON.push(generateStructuredData(roomoptions[i].current_week, roomoptions[i].rooms));
                }
                if (roomoptions[i].next_week && roomoptions[i].next_week.period_id) {
                    travelJSON.push(generateStructuredData(roomoptions[i].next_week, roomoptions[i].rooms));
                }
            }

        

            if (jsonld[1].member) {
                jsonld[1].member.event = travelJSON;
            }

            document.getElementById("structureddata").innerText = JSON.stringify(jsonld);
        } catch (e) {
            console.warn("Invalid JSON in Structured Data");
            return false;
        }
    }

    function getPersons(adults, children, totalPrice) {

       var arr = [];

       var totalPersons = parseInt(adults) + parseInt(children),
           pricePerPerson = parseFloat(totalPrice) / totalPersons;

       for (var i = 0; i < adults; i++) {
           var a = {
               adult: true,
               price: pricePerPerson,
               suffix: i + 1
           };
           arr.push(a);
       }

       for (var j = 0; j < children; j++) {
           var c = {
               adult: false,
               price: pricePerPerson,
               suffix: j + 1
           };
           arr.push(c);
       }

       return arr;
   };

    $scope.showPriceIncludes = function ($event, hotel) {

        $event.preventDefault();
        $event.stopPropagation();

        var content = "";

        var priceIncludeContent = $("#price-includes-content");

        content += "<ul class=\"list-inline\">";
        for (var i = 0; i < hotel.priceincludes.length; i++) {
            content += "<li><i class=\"fa fa-check\"></i> " + hotel.priceincludes[i] + "</li>";
        }
        content += "</ul>";

        content += priceIncludeContent.html();

        var modalBody = $("#price-include-modal").find(".modal-body");
        if (modalBody) {
            modalBody.empty().append(content);
        }

        $("#price-include-modal").modal("show");
   };

    String.format = function () {
       var s = arguments[0];
       for (var i = 0; i < arguments.length - 1; i++) {
           var reg = new RegExp("\\{" + i + "\\}", "gm");
           s = s !== undefined ?  s.replace(reg, arguments[i + 1]) : "";
       }
       return s;
    }

    function pushToDataLayer(hotel) {
        var travelsearch = $("#travelsearch");

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            "event": "travelsearch",
            "travelCheckinDate": hotel.departuredate,
            "travelCheckoutDate": hotel.returndate,
            "travelDays": hotel.dage,
            "travelContentIds": hotel.hotel,
            "travelCity": travelsearch.data("destination-name"),
            "travelCountry": travelsearch.data("country"),
            "travelAdults": hotel.voksne,
            "travelChildren": hotel.born,
            "travelTotalPersons": hotel.persons.length,
            "travelTransport": hotel.transport,
            "travelHotel": travelsearch.data("hotelname"),
            'ecommerce': {
                'detail': {
                    'products': [{
                        'name': travelsearch.data("hotelname"),
                        'id': hotel.hotel,
                        'price': hotel.totalprice,
                        'category': travelsearch.data("destination-name"),
                    }]
                }
            }
        });
    }

    $scope.submit = function () {
        if ($scope.hotelsearch.$valid) {

            var queryString = "";
            if (!angular.isUndefined($scope.hotel)) {
                queryString = "?";

                if ($rootScope.search.season) {
                    queryString = queryString + "saeson=" + $rootScope.search.season;
                }

                if ($scope.hotel.transport) {
                    queryString = queryString + "&transport=" + $scope.hotel.transport;
                }

                if ($scope.hotel.dage) {
                    queryString = queryString + "&dage=" + $scope.hotel.dage;
                }

                if ($scope.hotel.dato) {
                    queryString = queryString + "&dato=" + $scope.hotel.dato;
                }

                if ($scope.hotel.voksne) {
                    queryString = queryString + "&voksne=" + $scope.hotel.voksne;
                }

                if ($scope.hotel.born) {
                    queryString = queryString + "&born=" + $scope.hotel.born;
                }

                if ($scope.hotel.alder) {
                    if (angular.isObject($scope.hotel.alder)) {
                        var csvString = convertToCSV($scope.hotel.alder);
                        if (csvString !== "") {
                            queryString = queryString + "&alder=" + csvString;
                        }
                    }
                }
            }

            queryString = queryString + "&scroll=true";

            var url = [location.protocol, '//', location.host, location.pathname].join('');
            $window.location.href = url + queryString;
        }
    };

}]);

travelSearchApp.controller('FilterController', ["$rootScope","$scope","$cookies","$filter","travelSearchServices", function($rootScope,$scope,$cookies,$filter,travelSearchServices){
    var now = new Date();
    $scope.state = {};

    // Show prices including skipass as default
    $rootScope.filters.vispris = 1;
    $cookies.putObject("filters", $rootScope.filters, { 'path': window.location.pathname, 'expires': $rootScope.addDays(now, 7) });

    if (angular.element('#skipasspricechoice').length) {
        if (!angular.isUndefined(travelSearchServices.getCookie("search", "vispris"))) {
            $rootScope.filters.vispris = travelSearchServices.getCookie("search", "vispris");
            $cookies.putObject("filters", $rootScope.filters, { 'path': window.location.pathname, 'expires': $rootScope.addDays(now, 7) });
        }
    }

    
	if($rootScope.getParameterByName("vispris")){
		$rootScope.filters.vispris = parseInt($rootScope.getParameterByName("vispris"));
		$cookies.putObject("filters",$rootScope.filters,{'path': window.location.pathname ,'expires':$rootScope.addDays(now,7)});
    }


    var filterData = $rootScope.filters;
    var filterFields = Object.keys(filterData).map(function (key) {
        return {
            key: key,
            value: filterData[key]
        };
    });

    $scope.state.count = 0;

    angular.forEach(filterFields, function (obj, index) {

        // skip count
        if (obj.key === "sorter" || obj.key === "vispris" || obj.key === "plusminusWeeks")
            return;

        if (angular.isObject(obj.value)) {
            $scope.state.count += Object.keys(obj.value).length;
        }
        else if (obj.value === true) {
            $scope.state.count++;
        }
    });
	
	$scope.state.statustext = angular.element("#toggleFiltering").data("closed");
	$scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("closed");

   if ($scope.state.count > 1) {
       $scope.state.statustext = angular.element("#toggleFiltering").data("active");
       $scope.state.statustextInline = angular.element("#toggleFiltering").data("active");
   }

   $scope.openMobileFilters = function() {
       $("#filters").toggleClass("open");
   };

   $scope.openFilters = function() {
      $("#filters").slideToggle();
   };

   $scope.setFilterText = function() {
      if ($scope.state.statustext == angular.element("#toggleFiltering").data("open")){
         if ($scope.state.count > 1){
            $scope.state.statustext = angular.element("#toggleFiltering").data("active");
         } else {
            $scope.state.statustext = angular.element("#toggleFiltering").data("closed");
         }
      } else {
          $scope.state.statustext = angular.element("#toggleFiltering").data("open");
      }
   };

   $scope.setFilterTextInline = function () {
       if ($scope.state.statustextInline == angular.element("#toggleFiltering-inline").data("open")) {
           if ($scope.state.count > 1) {
               $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("active");
           } else {
               $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("closed");
           }
       } else {
           $scope.state.statustextInline = angular.element("#toggleFiltering-inline").data("open");
       }
   };

   $scope.resetFilters = function() {
      $rootScope.filters = {};
      $rootScope.filters.sorter = "0";
      $scope.updateFilters($rootScope.filters);
   };

    $scope.updateFilters = function (filterObj) {
        $rootScope.filters = filterObj;

        if (angular.element('#skipasspricechoice').length) {
            $rootScope.search.vispris = $rootScope.filters.vispris;
            $cookies.putObject("search", $rootScope.search, { 'path': '/', 'expires': $rootScope.addDays(now, 7) });
        }

        angular.forEach(filterObj, function (obj, key) {
            if (key == "marking") {
                angular.forEach(obj, function (value, index) {
                    if (!value) {
                        delete $rootScope.filters.marking[index];
                    }
                });
            } else {
                if (angular.isObject(obj)) {
                    angular.forEach(obj, function (value, index) {
                        if (!value) {
                            delete $rootScope.filters[key][index];
                        }
                    });
                }
                if (!obj) {
                    delete $rootScope.filters[key];
                }
            }
        });

        var filterData = $rootScope.filters;
        var filterFields = Object.keys(filterData).map(function (key) {
            return {
                key: key,
                value: filterData[key]
            };
        });

        $scope.state.count = 0;

        angular.forEach(filterFields, function (obj, index) {

            // skip count
            if (obj.key === "sorter" || obj.key === "vispris" || obj.key === "plusminusWeeks")
                return;

            if (angular.isObject(obj.value)) {
                $scope.state.count += Object.keys(obj.value).length;
            }
            else if (obj.value === true) {
                $scope.state.count++;
            }
        });

        $cookies.putObject("filters", $rootScope.filters, { 'path': window.location.pathname, 'expires': $rootScope.addDays(now, 7) });

        var sorting = $rootScope.getFilterSorting($rootScope.filters.sorter);

        // Using custom filter from controller to filter travels
        $scope.hotellist.filteredTravels = $filter("travelFilter")($scope.hotellist.travels, $rootScope.filters);
        $scope.hotellist.filteredTravels = $filter("orderBy")($scope.hotellist.filteredTravels, sorting);

        $scope.hotellist.filteredOutTravels = $scope.hotellist.travels.filter(function (obj) {
            return !$scope.hotellist.filteredTravels.includes(obj);
        });

    };

}]);

travelSearchApp.controller('WidgetFavoriteController', ["$rootScope", "$scope", "$timeout", "hotelService", function ($rootScope, $scope, $timeout, hotelService) {
    
    $scope.hotels = [];
    $scope.loading = false;

    var limit = 5;

    $scope.$watch('$root.favoriteHotels', function (newValue, oldValue) {
        
        var hotelCodes = $rootScope.favoriteHotels;

        if (hotelCodes != null && hotelCodes.length > 0) {
            hotelService.getHotels(hotelCodes.reverse().join(","), limit).then(function (response) {
                $scope.loading = true;
                $scope.hotels = response.data;

                $timeout(function () {
                    $scope.loading = false;
                }, 1000);
            });
        }
        else {
            $scope.hotels = [];
        }

    });

    $scope.renderStars = function (rating, icon) {
        var rating = parseFloat(rating);
        if (rating < 0) {
            rating = 0;
        }

        var stars = parseInt(rating);
        var icon = icon != null || icon != "" ? icon : "fa-star";

        var s = "";
        for (var i = 0; i < stars; i++) {
            s += "<i class=\"fa " + icon + "\"></i>\n";
        }
        return s;
    };

    $scope.getImageUrl = function (imageUrl, width, height) {
        var photo = imageUrl;
        return photo.substring(0, photo.indexOf("?")) + "?mode=crop&width=" + width + "&height=" + height + "&quality=75";
    };
    
}]);