angular.module('stg.travify.services').factory('cookieService', ['$cookies', function ($cookies) {

    var cookie = null;

    return {
        setCookieData: function (cookiename, obj, expiredays) {
            
            var now = new Date(),
            exp = new Date(now.getFullYear(), now.getMonth(), now.getDate() + expiredays);

            var options = {
                'path': '/',
                'expires': (expiredays ? exp : undefined)
            };

            $cookies.putObject(cookiename, obj, options);
        },
        getCookieData: function (cookiename) {
            cookie = $cookies.getObject(cookiename);
            return cookie;
        },
        clearCookieData: function (cookiename) {
            cookie = null;
            $cookies.remove(cookiename, { path: '/' });
        }
    };

}]);