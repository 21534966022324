angular.module("travelsearch.services").factory("travelSearchServices", ["$rootScope", "$http", "$cookies", "$filter", "$q", "settingsService", function ($rootScope, $http, $cookies, $filter, $q, settingsService) {
    var travelSearchService = {};

    var host = window.location.hostname;
    var travifyApi = "https://api.travify.dk";

    var clientIP = angular.element("body").data("ip");

    if (host.contains("test.") || host.contains("staging.") || host.contains(".local") || host.contains(".umbraco.io")) {
        travifyApi = "https://apitest.travify.dk";
    }

    var authorizationToken = null;

    convertToCSV = function (objArray) {
        var str = "";
        for (var i in objArray) {
            str = str + objArray[i];
            if (i < Object.keys(objArray).length - 1) {
                str = str + ",";
            }
        }
        return str;
    };

    convertObjectToCSV = function (obj) {
        var str = "";
        var count = 0;
        angular.forEach(obj, function (value, index) {
            str = str + index;
            if (count < Object.keys(obj).length - 1) {
                str = str + ",";
            }
            count++;
        });
        return str;
    };

    replaceChars = function (str) {
        // replace chars back to original values, see Filters.cshtml
        str = str.replace(/_ae1_/g, "æ");
        str = str.replace(/_ae2_/g, "Æ");

        str = str.replace(/_oe1_/g, "ø");
        str = str.replace(/_oe2_/g, "Ø");

        str = str.replace(/_aa1_/g, "å");
        str = str.replace(/_aa2_/g, "Å");

        str = str.replace(/_ae3_/g, "ä");
        str = str.replace(/_ae4_/g, "Ä");

        str = str.replace(/_oe3_/g, "ö");
        str = str.replace(/_oe4_/g, "Ö");

        str = str.replace(/_ue1_/g, "ü");
        str = str.replace(/_ue2_/g, "Ü");

        str = str.replace(/_ss_/g, "ß");

        return str;
    };

    checkDestCode = function (destinationCode, hotelCode) {
        if ((destinationCode.indexOf("LAND") !== -1 || destinationCode.indexOf("ALL") !== -1) && destinationCode != "TRANSPORT" && angular.isUndefined(hotelCode) && hotelCode == null) {
            return "LAND";
        } else {
            return destinationCode;
        }
    };

    generateQueryString = function (obj) {
        var queryString = "";
        angular.forEach(obj, function (obj, index) {
            if (index != "alldates") {
                if (angular.isObject(obj)) {
                    if (index == "marking") {
                        var csvString = convertObjectToCSV(obj);
                        // ensure to replace chars for querystring
                        csvString = replaceChars(csvString);
                        queryString = queryString + "&" + index + "=" + csvString;
                    } else {
                        queryString = queryString + "&" + index + "=" + convertToCSV(obj);
                    }
                } else {
                    if (obj) {
                        queryString = queryString + "&" + index + "=" + obj;
                    }
                }
            }
        });
        return queryString;
    };

    travelSearchService.setCookie = function (cookiename, cookieobject, cookievalue, cookieoptions) {
        if (!angular.isUndefined($cookies.getObject(cookiename))) {
            var cookieObj = $cookies.getObject(cookiename);
            cookieObj[cookieobject] = cookievalue;
            $cookies.putObject(cookiename, cookieObj, cookieoptions);
        } else {
            $cookies.putObject(cookiename, cookievalue, cookieoptions);
        }
    };

    travelSearchService.getCookie = function (cookiename, cookieobject) {
        var cookieObj = $cookies.getObject(cookiename);
        return cookieObj[cookieobject];
    };

    travelSearchService.checkCookie = function (cookiename, cookieobject) {
        var cookieObj = $cookies.getObject(cookiename);
        return !angular.isUndefined(cookieObj[cookieobject]);
    };

    travelSearchService.getDestinations = function (season) {
        var req = {
            method: 'GET',
            url: '/Umbraco/Api/SearchBox/GetDestinations/',
            params: { season: season },
            cache: true
        };

        return $http(req);
    };

    travelSearchService.getTransports = function (id, season) {
        var req = {
            method: 'GET',
            url: '/Umbraco/Api/SearchBox/GetTransportMethodFromId/',
            params: { id: id, season: season },
            cache: true
        };

        return $http(req);
    };

    getRequestUrl = function () {
        var currentDomain = window.location.hostname;
        var bookDomain = "";
        var requestUrl = "";

        bookDomain = "book." + currentDomain.replace("www.", "").replace("test.", "").replace("staging.", "").replace("oerskov.", "").replace("umbraco.io", "").replace(".local", "");

        requestUrl = "https://" + bookDomain;

        return requestUrl;
    };

    getQueryString = function () {
        var queryString = "";

        if ($cookies.get("salg") != null) {
            queryString += "&salg=" + $cookies.get("salg");
        }

        if ($cookies.get("agent") != null) {
            queryString += "&agent=" + $cookies.get("agent");
        }

        if ($cookies.get("agentlink") != null) {
            queryString += "&agentlink=" + $cookies.get("agentlink");
        }

        if ($cookies.get("__CT_Data") != null) {
            queryString += "&clicktale=1";
        }

        return queryString;
    };

    travelSearchService.getNightsAndDates = function (season, destinationCode, transportCode) {
        var requestUrl = "";
        var searchString = "";

        var req = {
            method: 'GET',
            cache: true
        };

        req.headers = {
            'Accept': 'application/json'
        };

        requestUrl = travifyApi + "/arrival_dates?bureau=1";

        if (!angular.isUndefined(season) && season !== null) {
            searchString += "&subseason=" + season;
        }
        if (!angular.isUndefined(destinationCode) && destinationCode !== null) {
            searchString += "&destination_ids=" + destinationCode.replace("LAND-", "").replace("ALL-", "").replace(new RegExp("-", "g"), ",");
        }
        //if (!angular.isUndefined(hotelCode) && hotelCode !== null) {
        //    searchString += "&id_hotel=" + hotelCode;
        //}

        if (!angular.isUndefined(transportCode) && transportCode !== null) {
            searchString += "&transport_code=" + transportCode;
        }

        searchString += "&client_ip_address=" + clientIP;

        var promises = {
            authorizationToken: settingsService.getAuthenticationToken()
        };

        return $q.all(promises).then(function (values) {
            authorizationToken = values.authorizationToken;

            searchString += "&auth_token=" + authorizationToken;

            req.url = requestUrl + searchString;

            return $http(req);
        });
    };

    travelSearchService.getHotels = function () {
        var requestUrl = "";
        var searchString = "";

        var hotelSearch = $cookies.getObject("search");
        
        if (!angular.isUndefined(hotelSearch.season) && hotelSearch.season !== null) {
            searchString += "&season=" + hotelSearch.season;
        }

        if (!angular.isUndefined(hotelSearch.dest) && hotelSearch.dest !== null) {
            searchString += "&dest=" + hotelSearch.dest.replace("LAND-", "").replace("ALL-", "").replace(new RegExp("-", "g"), ",");
        }

        if (!angular.isUndefined(hotelSearch.hotel) && hotelSearch.hotel !== null) {
			searchString += "&hotel=" + hotelSearch.hotel;
		}
        if (!angular.isUndefined(hotelSearch.hoteller) && hotelSearch.hoteller !== null) {
            searchString += "&hoteller=" + hotelSearch.hoteller.replace(new RegExp("-", "g"), ",");
		}
        
        if (!angular.isUndefined(hotelSearch.transport) && hotelSearch.transport !== null) {
            searchString += "&transport=" + hotelSearch.transport;
        }
        if (!angular.isUndefined(hotelSearch.periodid) && hotelSearch.periodid !== null) {
            searchString += "&periodid=" + hotelSearch.periodid;
        }
        if (!angular.isUndefined(hotelSearch.voksne) && hotelSearch.voksne !== null) {
            searchString += "&voksne=" + hotelSearch.voksne;
        }
        if (!angular.isUndefined(hotelSearch.born) && hotelSearch.born !== null) {
            searchString += "&born=" + hotelSearch.born;
        }
        if (!angular.isUndefined(hotelSearch.alder) && hotelSearch.alder !== null && !angular.isUndefined(hotelSearch.born) && hotelSearch.born !== null && hotelSearch.born > 0) {
            searchString += "&alder=" + convertToCSV(hotelSearch.alder);
        }
        if (!angular.isUndefined($rootScope.language) && $rootScope.language !== null) {
            searchString += "&language=" + $rootScope.language;
		}

        if (!angular.isUndefined($cookies.getObject("filters"))) {
            searchString += generateQueryString($cookies.getObject("filters"));
        }

        var req = {
            method: 'GET',
            url: "",
            cache: true
        };

        var promises = {
            authorizationToken: settingsService.getAuthenticationToken()
        };

        return $q.all(promises).then(function (values) {
            authorizationToken = values.authorizationToken;

            requestUrl = "/Umbraco/Api/TravifyTravel/GetTravels?Authorization=" + authorizationToken;

            req.url = requestUrl + searchString;

            return $http(req);
        });
    };

    travelSearchService.getHotel = function (hotel) {
        var requestUrl = "";
        var searchString = "";
        var childAges = convertToCSV(hotel.alder);

        var req = {
            method: 'GET',
            cache: true
        };

        req.headers = {
            'Accept': 'application/json'
        };

        requestUrl = travifyApi + "/trips/combinations?bureau=1";

        if (!angular.isUndefined($rootScope.language) && $rootScope.language !== null) {
            searchString += "&language_code=" + $rootScope.language;
        }

        if (!angular.isUndefined(hotel.hotel) && hotel.hotel !== null) {
            searchString += "&accomodation_code=" + hotel.hotel;
        }  

        if (!angular.isUndefined(hotel.transport) && hotel.transport !== null) {
            searchString += "&transport_code=" + hotel.transport;
        }

        if (!angular.isUndefined(hotel.periodid) && hotel.periodid !== null) {
            searchString += "&period_id=" + hotel.periodid;
        }

        if (!angular.isUndefined(hotel.transport_id) && hotel.transport_id !== null) {
            searchString += "&transport_id=" + hotel.transport_id;
        }
            
        if (!angular.isUndefined(hotel.voksne) && hotel.voksne !== null) {
            // Assume at least 1 adult
            var ageString = "30";

            for (var i = 1; hotel.voksne > i; i++)
            {
                ageString += ",30";
            }

            if (!angular.isUndefined(hotel.born) && hotel.born !== null && hotel.born > 0) {   
                ageString += "," + childAges;
            }

            searchString += "&age_string=" + ageString;
        }

        searchString += "&client_ip_address=" + clientIP;

        var promises = {
            authorizationToken: settingsService.getAuthenticationToken()
        };

        return $q.all(promises).then(function (values) {
            authorizationToken = values.authorizationToken;

            searchString += "&auth_token=" + authorizationToken;

            req.url = requestUrl + searchString;

            return $http(req);
        });
    };

    travelSearchService.getLastMinuteHotels = function () {

        var req = {
            method: 'GET',
            url: "",
            cache: true
        };

        var promises = {
            authorizationToken: settingsService.getAuthenticationToken()
        };

        return $q.all(promises).then(function (values) {
            authorizationToken = values.authorizationToken;

            requestUrl = "/Umbraco/Api/TravifyTravel/GetTravels?Authorization=" + authorizationToken + "&LastMinute=true";

            req.url = requestUrl;

            return $http(req);
        });
    };

    return travelSearchService;
}]);